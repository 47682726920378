import { apiClient } from "./base.service";

const imageService = {
  getAll: async () => await apiClient.get("/images"),
  get: async (id: string) => await apiClient.get("/images/" + id),
  create: async (payload: any) => await apiClient.post("/images", payload),
  update: async (id: string, payload: any) =>
    await apiClient.put("/images/" + id, payload),
  remove: async (id: string) => await apiClient.delete("/images/" + id),
  upload: async (
    payload: FormData,
    onUploadProgress: (ProgressEvent: any) => void
  ) => await apiClient.post("/images/upload", payload, { onUploadProgress }),
};

export default imageService;
