import UploadSection from "@/components/auth/admin/UploadSection";
import { UserData } from "@/data/dtos/user.dto";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MuiTelInput } from "mui-tel-input";
import InputLabel from "@/components/InputLabel";
import { UserStatus } from "@/data/enums/user-status.enum";
import { Roles } from "@/data/enums/roles.enum";
import { create } from "@/actions/users.actions";
import InputError from "@/components/core/InputError";
import { clearMessage } from "@/actions/message";

const roleArray = [
  { value: Roles.ADMIN, label: "Admin" },
  { value: Roles.TEACHER, label: "Eğitmen" },
  { value: Roles.STUDENT, label: "Oğrenci" },
  { value: Roles.ACCOUNTER, label: "Muhasebe" },
  { value: Roles.SUPPORT, label: "Destek" },
  { value: Roles.MANAGER, label: "Yönetici" },
  { value: Roles.SUPERVISOR, label: "Supervizor" },
  { value: Roles.PUBLISHER, label: "Yayıncı" },
];

const userStatusArray = [
  { value: UserStatus.ACTIVE, label: "Aktif" },
  { value: UserStatus.INACTIVE, label: "Pasif" },
  { value: UserStatus.BANNED, label: "Engellenen" },
  { value: UserStatus.DELETED, label: "Silinmiş" },
  { value: UserStatus.PENDING, label: "Onay Bekliyor" },
  { value: UserStatus.VERIFIED, label: "Onaylanmış" },
  { value: UserStatus.UNVERIFIED, label: "Onaylanmamış" },
  { value: UserStatus.REJECTED, label: "Reddedildi" },
];

export default function CreateUser() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<UserData>({
    name: "",
    surname: "",
    email: "",
    role: "student",
    profileImage: "",
    status: "active",
    phone: "+90",
  });
  const { currentImage } = useSelector((state: any) => state.images);
  const { message } = useSelector((state: any) => state.message);

  const handleSubmit = () => {
    console.log(formData);
    dispatch(create(formData) as any);
  };

  return (
    <div className="bg-white rounded shadow w-full p-4">
      {message?.message && (
        <div className="flex flex-col gap-4 rounded bg-red-500 p-4 text-white">
          {message.message}
        </div>
      )}
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}
      >
        <Typography variant="h6">Kullanıcı Oluştur</Typography>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-center">
          <div className="flex flex-col col-span-4 md:col-span-2 lg:col-span-1">
            <TextField
              label="Ad"
              variant="outlined"
              value={formData.name}
              fullWidth
              onChange={(e) => {
                setFormData({ ...formData, name: e.target.value });
              }}
            />
            <InputError messages={message?.errors?.name} className="mt-2" />
          </div>
          <div className="flex flex-col col-span-4 md:col-span-2 lg:col-span-1">
            <TextField
              label="Soyad"
              variant="outlined"
              value={formData.surname}
              onChange={(e) => {
                setFormData({ ...formData, surname: e.target.value });
              }}
            />
            <InputError messages={message?.errors?.surname} className="mt-2" />
          </div>
          <div className="flex flex-col col-span-4 md:col-span-2 lg:col-span-1">
            <TextField
              label="E-Posta"
              variant="outlined"
              value={formData.email}
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value });
              }}
            />
            <InputError messages={message?.errors?.email} className="mt-2" />
          </div>
          <div className="flex flex-col col-span-4 md:col-span-2 lg:col-span-1">
            <TextField
              label="Şifre"
              variant="outlined"
              type="password"
              value={formData.password}
              onChange={(e) => {
                setFormData({ ...formData, password: e.target.value });
              }}
            />
            <InputError messages={message?.errors?.password} className="mt-2" />
          </div>
          <div className="flex flex-col col-span-4 md:col-span-2">
            <TextField
              label="Kimlik Numarası"
              variant="outlined"
              value={formData.identityNumber}
              fullWidth
              onChange={(e) => {
                setFormData({ ...formData, identityNumber: e.target.value });
              }}
            />
            <InputError
              messages={message?.errors?.identityNumber}
              className="mt-2"
            />
          </div>
          <div className="flex flex-col col-span-4 md:col-span-2">
            <MuiTelInput
              label="Telefon"
              variant="outlined"
              fullWidth
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e })}
            />
            <InputError messages={message?.errors?.phone} className="mt-2" />
          </div>
          <div className="col-span-2">
            <InputLabel id="user-status">Kullanıcı Durumu</InputLabel>
            <Select
              labelId="user-status"
              id="user-status"
              value={formData.status}
              variant="outlined"
              fullWidth
              label="Status"
              onChange={(e) => {
                setFormData({ ...formData, status: e.target.value });
              }}
            >
              {userStatusArray.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>
            <InputError messages={message?.errors?.status} className="mt-2" />
          </div>
          <div className="col-span-2 flex flex-col">
            <InputLabel id="user-role">Rol</InputLabel>
            <Select
              labelId="user-role"
              id="user-role"
              value={formData.role}
              variant="outlined"
              fullWidth
              label="Role"
              onChange={(e) => {
                setFormData({ ...formData, role: e.target.value });
              }}
            >
              {roleArray.map((role) => (
                <MenuItem key={role.value} value={role.value}>
                  {role.label}
                </MenuItem>
              ))}
            </Select>
            <InputError messages={message?.errors?.role} className="mt-2" />
          </div>
          <div className="col-span-4 flex flex-col">
            <UploadSection
              images={[formData?.profileImage ?? null]}
              title="Profil Resmi"
              onChange={(field: string, value: any) => {
                console.log(value.url, field);
                setFormData({ ...formData, profileImage: currentImage?.url });
              }}
            />
            <InputError
              messages={message?.errors?.profileImage}
              className="mt-2"
            />
          </div>
        </div>
        <Button
          sx={{ mt: 2, width: "5rem" }}
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Kaydet
        </Button>
      </Box>
    </div>
  );
}
