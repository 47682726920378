import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import CommentCard from "./CommentCard";
import { getAllAuth } from "@/actions/courses.actions";
import Skeleton from "react-loading-skeleton";
import { ReviewDto } from "@/data/dtos/review.dto";

export default function LastComments() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { courses } = useSelector((state: any) => state.courses);
  const [latestComments, setLatestComments] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const fetchComments = async () => {
      if (isMounted) {
        await dispatch(getAllAuth() as any);
        setLoading(false);
      }
    };
    fetchComments();
    setLoading(true);
    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    if (courses?.length > 0) {
      const latestComments = courses
        .map((course: any) => course.reviews)
        .flat()
        .sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        )
        .slice(0, 5);

      setLatestComments(latestComments);
    }
  }, [courses]);

  if (loading) {
    return <Skeleton height={200} count={5} />;
  }

  return (
    <div className="min-h-[200px]">
      <h3 className="text-lg font-bold mb-4">Son Yorumlar</h3>
      <div className="grid grid-cols-1 gap-4">
        {latestComments?.map((comment: ReviewDto, index) => (
          <CommentCard key={comment._id} comment={comment} />
        ))}
      </div>
    </div>
  );
}
