import React from "react";
import { Link } from "react-router-dom";
import { url } from "../../utils";

export default function ApplicationLogo({ className }: { className: string }) {
  return (
    <div className={`w-28 ${className}`}>
      <Link to={url("Home")}>
        <img src="/logo.png" alt="logo" className="w-full" />
      </Link>
    </div>
  );
}
