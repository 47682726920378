import { generatePath } from "react-router-dom";
import routes from "./Routes";

export const url = (path: string, params = {}) => {
  let lastRoute: any,
    url: string = "";
  const paths = path.split("."); // "." ile ayrılmış path dizisini al

  paths.forEach((p: any) => {
    if (!lastRoute) {
      lastRoute = routes.find((r) => {
        // console.log(r.name, p);
        return r.name === p;
      });
      if (!lastRoute) {
        throw new Error(`Route not found: ${p}`); // Hata fırlat
      }
      url = lastRoute.path;
    } else {
      if (!lastRoute.children) {
        throw new Error(`Children not found for route: ${p}`); // Hata fırlat
      }

      lastRoute = lastRoute.children.find((r: any) => {
        return r.name === p;
      });
      if (!lastRoute) {
        throw new Error(`Route not found in children: ${p}`); // Hata fırlat
      }
      url += "/" + lastRoute.path;
    }
  });

  return generatePath(url.replace(/\/\//gi, "/"), params);
};

export const seo = (name: string) => {
  if (name) {
    return name.toLowerCase().replace(/ /g, "-");
  }
  return "";
};

export const formatMoney = (amount: number) => {
  return new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
  }).format(amount);
};

export const genProfileImage = (name: string, surname: string) => {
  return `https://ui-avatars.com/api/?name=${name + " " + surname}`;
};

export const replacePlaceholders = (
  content: string,
  replacements: Record<string, string>
) => {
  console.log(replacements);
  return content.replace(
    /{\s*(.*?)\s*}/g,
    (_, key) => replacements[key.trim()] || `{${key.trim()}}`
  );
};

export const isValidLuhn = (cardNumber) => {
  let sum = 0;
  let alternate = false;
  const digits = cardNumber.replace(/\D/g, "").split("").reverse();

  for (let i = 0; i < digits.length; i++) {
    let num = parseInt(digits[i], 10);

    if (alternate) {
      num *= 2;
      if (num > 9) num -= 9;
    }

    sum += num;
    alternate = !alternate;
  }

  return sum % 10 === 0;
};
