import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAll, remove } from "@/actions/orders.actions";
import { useDispatch, useSelector } from "react-redux";
import Table from "@/components/core/Table";
import { formatMoney, url } from "@/utils";
import { DataGrid } from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import OptionsMenu from "@/components/core/TableOptionMenu";
import { OrderData } from "@/data/dtos/order.dto";

export default function OrderList() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { orders, status } = useSelector((state: any) => state.order);

  const handleDelete = async (id) => {
    dispatch(remove(id) as any);
  };

  useEffect(() => {
    if (status === "idle" && orders.length === 0) {
      console.log("useEffect 1");
      dispatch(getAll() as any);
    }
  }, [dispatch, status, orders]);

  const pageSizeOptions = [25, 50, 100];

  const columns = [
    {
      field: "_id",
      headerName: "Sipariş İd",
      flex: 0.1,
    },
    {
      field: "buyer",
      headerName: "Satın Alan Kişi",
      flex: 0.2,
      renderCell: (params) => {
        console.log(params);
        return (
          <Link
            className="underline text-blue-500"
            to={url("Admin.Users.View", { id: params.row.buyer._id })}
          >
            {params.row.buyer.name} {params.row.buyer.surname}
          </Link>
        );
      },
    },
    {
      field: "prices",
      headerName: "Satış Miktarı",
      flex: 0.2,
      renderCell: (params: any) => {
        console.log(params);
        const price = params.row.prices.reduce(
          (a: any, b: any) => a + b.price - b.discount,
          0
        );
        const saledCourses = params.row.prices.reduce(
          (a, b) => [
            ...a,
            {
              title: b.course.title,
              price: b.price - b.discount,
              id: b.course._id,
            },
          ],
          []
        );

        return (
          <div className="group inline-block">
            <span>{formatMoney(price)}</span>
            {/* Dropdown */}
            <div className="absolute mt-2 opacity-0 group-hover:opacity-100 group-hover:translate-y-0 translate-y-2 transform -translate-x-3/4 transition-all duration-200 ease-in-out w-48 p-2 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-md shadow-lg z-50">
              {saledCourses?.map((course) => (
                <div
                  key={course.id}
                  className="flex items-center justify-between truncate max-w-full"
                >
                  <span className="mr-2 truncate">{course.title}</span>
                  <span>{formatMoney(course.price)}</span>
                </div>
              ))}
            </div>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Satış durumu",
      flex: 0.3,
    },
    {
      field: "courses",
      headerName: "Atölyeler",
      flex: 0.5,
      valueGetter: (param: any) => {
        return param.map((course) => course.title).join(",\n ");
      },
    },
    {
      field: "options",
      headerName: "Seçenekler",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation(); // Prevent row selection on click
          // You can manage the menu state in the parent or directly in the component
        };

        return (
          <Stack direction="row" spacing={1}>
            <OptionsMenu
              id={params.row._id}
              onEdit={() => {
                navigation(url("Admin.Orders.Edit", { id: params.row._id }));
              }}
              onDelete={handleDelete}
              onOpenMenu={handleMenuOpen} // Pass the handler to OptionsMenu
              customField={{
                title: "Fatura Oluştur",
                action: () => {
                  navigation(
                    url("Admin.Invoices.Create", { id: params.row._id })
                  );
                },
              }}
            />
          </Stack>
        );
      },
    },
  ];

  const rows = orders.map((order: OrderData, index) => {
    return {
      _id: order._id,
      no: index + 1,
      buyer: order.buyer,
      status: order.status,
      prices: order.items.map((item) => item),
      courses: order.items.map((item) => item.course),
    };
  });

  if (orders?.length > 0) {
    return (
      <div className="w-full bg-white">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={pageSizeOptions}
          checkboxSelection
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: pageSizeOptions[0] },
            },
          }}
          getRowId={(row) => row._id} // Benzersiz ID için _id kullan
        />
      </div>
    );
  } else {
    return <div>No Content</div>;
  }
}
