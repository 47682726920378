import {
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface OptionsMenuProps {
  id: string;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onView?: (id: string) => void;
  onOpenMenu: (event: React.MouseEvent<HTMLElement>) => void;
  customField?: {
    title: string;
    action: (id: string) => void;
  };
}

const OptionsMenu: React.FC<OptionsMenuProps> = ({
  id,
  onEdit,
  onDelete,
  onView,
  onOpenMenu,
  customField,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onOpenMenu(event);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    setOpenDialog(true); // Open confirmation dialog
    handleMenuClose();
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // Close confirmation dialog
  };

  const handleConfirmDelete = () => {
    onDelete(id); // Execute delete action if confirmed
    setOpenDialog(false);
  };

  return (
    <>
      <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {onView && (
          <MenuItem
            onClick={() => {
              onView(id);
              handleMenuClose();
            }}
          >
            Görüntüle
          </MenuItem>
        )}
        <MenuItem onClick={(e) => customField?.action(id)}>
          {customField?.title}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onEdit(id);
            handleMenuClose();
          }}
        >
          Düzenle
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>Sil</MenuItem>
      </Menu>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Silmeyi Onayla</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bu kaynağı silmek istediğinizden emin misiniz? Bu işlemi geri
            alamazsınız.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            İptal
          </Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            Evet, SİL
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OptionsMenu;
