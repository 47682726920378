import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login, register } from "../../../../actions/authAction";
import InputLabel from "../../../../components/InputLabel";
import TextInput from "../../../../components/core/TextInput";
import InputError from "../../../../components/core/InputError";
import PrimaryButton from "../../../../components/PrimaryButton";
import { url } from "../../../../utils";
import IllumimationModal from "@/components/auth/pages/IllumimationModal";
import PrivacyModal from "@/components/auth/pages/PrivacyModal";
import MembershipModal from "@/components/auth/pages/MembershipModal";

//TODO: add form validation https://www.freecodecamp.org/news/react-form-validation-zod-react-hook-form/

export default function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message } = useSelector((state: any) => state.message);

  const [formData, setFormData] = useState({
    email: "",
    name: "",
    surname: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [isIlluminationOpen, setIsIlluminationOpen] = useState(false);
  const [isMembershipOpen, setIsMembershipOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [illuminationAgreement, setIlluminationAgreement] = useState(false);
  const [privacyAgreement, setPrivacyAgreement] = useState(false);
  const [membershipAgreement, setMembershipAgreement] = useState(false);
  const [formErrors, setFormErrors] = useState<any>({});

  const validateForm = () => {
    const errors: any = {};
    if (!formData.name.trim()) errors.name = "Ad alanı boş bırakılamaz.";
    if (!formData.surname.trim())
      errors.surname = "Soyad alanı boş bırakılamaz.";
    if (!formData.email.trim()) {
      errors.email = "E-posta alanı boş bırakılamaz.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Geçerli bir e-posta adresi giriniz.";
    }
    if (!formData.password.trim()) {
      errors.password = "Şifre alanı boş bırakılamaz.";
    } else if (formData.password.length < 6) {
      errors.password = "Şifre en az 6 karakter olmalıdır.";
    } else if (
      !/\d/.test(formData.password) ||
      !/[!@#$%^&*]/.test(formData.password)
    ) {
      errors.password = "Şifre en az bir rakam ve özel karakter içermelidir.";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      await dispatch(
        register({
          ...formData,
          role: "student",
          contents: [
            { _id: "6766c9af42e64f96db18e7ec", content: illuminationAgreement },
            { _id: "6766cb0f42e64f96db18e823", content: privacyAgreement },
            { _id: "6766cb0f42e64f96db18e823", content: membershipAgreement },
          ],
        }) as any
      );
      await dispatch(
        login({
          email: formData.email,
          password: formData.password,
          remember: false,
        }) as any
      );
      navigate("/");
    } catch (err) {
      console.error("Registration failed", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="rounded-lg w-full border-slate-100 dark:text-slate-100 grid gap-4 p-6 bg-white shadow-md">
      <h1 className="text-xl font-bold text-gray-800 text-center w-full">
        Kayıt Ol
      </h1>
      {isIlluminationOpen && (
        <IllumimationModal
          isOpen={isIlluminationOpen}
          setIsOpen={setIsIlluminationOpen}
          agreementId={"6766c9af42e64f96db18e7ec"}
          setContent={setIlluminationAgreement}
        />
      )}
      {isPrivacyOpen && (
        <PrivacyModal
          isOpen={isPrivacyOpen}
          setIsOpen={setIsPrivacyOpen}
          agreementId={"6766cb0f42e64f96db18e823"}
          setContent={setPrivacyAgreement}
        />
      )}
      {isMembershipOpen && (
        <MembershipModal
          isOpen={isMembershipOpen}
          setIsOpen={setIsMembershipOpen}
          agreementId={"6766c82c42e64f96db18e77e"}
          setContent={setMembershipAgreement}
        />
      )}
      {/* Error Message */}
      {message?.message && (
        <div className="rounded bg-red-100 text-red-600 p-4" role="alert">
          {message.message}
        </div>
      )}

      {/* Form Fields */}
      <div className="grid grid-cols-2 gap-4">
        <div>
          <InputLabel htmlFor="name" value="Adınız" />
          <TextInput
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md text-gray-700"
            autoComplete="name"
            aria-describedby="name-error"
          />
          <InputError messages={message?.errors?.name} className="mt-2" />
          {formErrors.name && (
            <p className="text-red-600 text-sm" id="name-error">
              {formErrors.name}
            </p>
          )}
        </div>
        <div>
          <InputLabel htmlFor="surname" value="Soyadınız" />
          <TextInput
            type="text"
            id="surname"
            name="surname"
            value={formData.surname}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md text-gray-700"
            autoComplete="surname"
            aria-describedby="surname-error"
          />
          <InputError messages={message?.errors?.surname} className="mt-2" />
          {formErrors.surname && (
            <p className="text-red-600 text-sm" id="surname-error">
              {formErrors.surname}
            </p>
          )}
        </div>
      </div>

      {/* Email and Password */}
      <div>
        <InputLabel htmlFor="email" value="E-posta" />
        <TextInput
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          className="mt-1 block w-full border rounded-md text-gray-700"
          autoComplete="email"
          aria-describedby="email-error"
        />
        <InputError messages={message?.errors?.email} className="mt-2" />
        {formErrors.email && (
          <p className="text-red-600 text-sm" id="email-error">
            {formErrors.email}
          </p>
        )}
      </div>
      <div>
        <InputLabel htmlFor="password" value="Şifre" />
        <TextInput
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          className="mt-1 block w-full border rounded-md text-gray-700"
          autoComplete="new-password"
          aria-describedby="password-error"
        />
        <InputError messages={message?.errors?.password} className="mt-2" />
        {formErrors.password && (
          <p className="text-red-600 text-sm" id="password-error">
            {formErrors.password}
          </p>
        )}
      </div>

      {/* Agreements */}
      <div className="text-xs text-gray-500 mt-2">
        Kişisel verileriniz,{" "}
        <button
          onClick={() => setIsIlluminationOpen(true)}
          className="text-indigo-600 underline"
        >
          Aydınlatma Metni
        </button>{" "}
        kapsamında işlenmektedir. “Kayıt Ol” butonuna basarak{" "}
        <button
          onClick={() => setIsMembershipOpen(true)}
          className="text-indigo-600 underline"
        >
          Üyelik Sözleşmesi
        </button>{" "}
        ve{" "}
        <button
          onClick={() => setIsPrivacyOpen(true)}
          className="text-indigo-600 underline"
        >
          Gizlilik Politikası
        </button>{" "}
        ’nı okuduğunuzu ve kabul ettiğinizi onaylıyorsunuz.
      </div>
      {formErrors.agreements && (
        <p className="text-red-600 text-sm">{formErrors.agreements}</p>
      )}

      {/* Buttons */}
      <div className="flex justify-between items-center">
        <PrimaryButton onClick={handleRegister} disabled={loading}>
          {loading ? "Kayıt Oluyor..." : "Kayıt Ol"}
        </PrimaryButton>
        <Link
          to={url("Auth.Login")}
          className="underline text-sm text-gray-600 hover:text-gray-900 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Zaten hesabım var?
        </Link>
      </div>
    </div>
  );
}
