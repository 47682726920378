import React, { useRef } from "react";
import { Typography, Box } from "@mui/material";
import { formatMoney } from "../../../utils";
import Accordion from "../../core/Accordion";
import CourseDetail from "@/app/views/courses/CourseDetail";

interface ConfirmationProps {
  courseData: any;
}

const Confirmation: React.FC<ConfirmationProps> = ({ courseData }) => {
  const mainImageRef = useRef<HTMLImageElement>(null);
  const changeImage = (e: any) => {
    if (mainImageRef.current) {
      const backgroundImage = e.target.style.backgroundImage;
      const url = backgroundImage.slice(5, -2);
      mainImageRef.current.src = url;
    }
  };

  console.log(courseData);

  return (
    <Box>
      <Typography variant="h6">Onay ve Oluştur</Typography>
      <CourseDetail courseData={courseData} />
    </Box>
  );
};

export default Confirmation;
