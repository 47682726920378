import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "@/actions/courses.actions";
import { UserData } from "@/data/dtos/user.dto";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useInView } from "react-intersection-observer";

export default function InstructorSection({
  instructors = [],
}: {
  instructors: UserData[] | undefined;
}) {
  const dispatch = useDispatch();
  const { courses = [] } = useSelector((state: any) => state.courses || {});
  const [loading, setLoading] = useState(true);
  const [visibleInstructors, setVisibleInstructors] = useState(6);

  // Ensure instructors is always an array
  const validInstructors = Array.isArray(instructors) ? instructors : [];

  // Memoized student count
  const studentCount = useMemo(() => {
    return courses?.reduce((total: number, course: any) => {
      return total + (course?.participants?.length || 0);
    }, 0);
  }, [courses]);

  // Memoized average rating
  const averageRating = useMemo(() => {
    let totalRating = 0;
    let reviewCount = 0;

    courses?.forEach((course: any) => {
      if (course?.reviews?.length > 0) {
        course.reviews.forEach((review: any) => {
          totalRating += review.rating;
          reviewCount++;
        });
      }
    });

    return reviewCount > 0
      ? parseFloat((totalRating / reviewCount).toFixed(1))
      : 0;
  }, [courses]);

  // Fetch courses if not already loaded
  useEffect(() => {
    if (!courses?.length) {
      dispatch(getAll() as any);
    }
    setTimeout(() => setLoading(false), 1000); // Simulated loading
  }, [courses, dispatch]);

  // Handle "Load More"
  const handleLoadMore = () => {
    setVisibleInstructors((prev) => prev + 6);
  };

  const instructorsContent =
    validInstructors.length > 0
      ? validInstructors
          .slice(0, visibleInstructors)
          .map((instructor: any, index) => (
            <InstructorCard
              key={instructor._id ?? index}
              instructor={instructor}
              studentCount={studentCount}
              averageRating={averageRating}
            />
          ))
      : !loading && <p className="text-gray-500 italic">Eğitmen bulunamadı.</p>;

  return (
    <section className="my-8">
      <h3 className="text-2xl font-bold mb-6 text-gray-800">
        Eğitmen Bilgileri
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {loading
          ? Array.from({ length: 6 }).map((_, index) => (
              <SkeletonCard key={index} />
            ))
          : instructorsContent}
      </div>
      {validInstructors.length > visibleInstructors && (
        <div className="flex justify-center mt-6">
          <button
            onClick={handleLoadMore}
            className="px-6 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition"
          >
            Daha Fazla Yükle
          </button>
        </div>
      )}
    </section>
  );
}

const SkeletonCard = () => (
  <div className="bg-white shadow-lg rounded-lg overflow-hidden border p-6">
    <Skeleton circle height={128} width={128} className="mx-auto mb-4" />
    <Skeleton width="60%" className="mx-auto mb-2" />
    <Skeleton width="40%" className="mx-auto mb-2" />
    <Skeleton width="80%" className="mx-auto mb-4" />
    <div className="flex justify-around">
      <Skeleton width={50} height={20} />
      <Skeleton width={50} height={20} />
    </div>
  </div>
);

const InstructorCard = ({
  instructor,
  studentCount,
  averageRating,
}: {
  instructor: UserData;
  studentCount: number;
  averageRating: number;
}) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [imageError, setImageError] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className={`bg-white shadow-lg rounded-lg overflow-hidden border hover:shadow-xl transition transform hover:scale-105 ${
        inView ? "opacity-100" : "opacity-0"
      }`}
    >
      <div className="relative p-6 text-center">
        <div className="mx-auto w-32 h-32 rounded-full overflow-hidden border-4 border-indigo-100 mb-4">
          {imageLoading && <Skeleton circle height={128} width={128} />}
          {!imageError && inView && (
            <img
              className={`object-cover object-center w-full h-full ${
                imageLoading ? "blur-sm" : "blur-none"
              }`}
              src={instructor?.profileImage || "/images/default-avatar.png"}
              alt={`${instructor?.name} ${instructor?.surname}`}
              onLoad={() => setImageLoading(false)}
              onError={() => setImageError(true)}
            />
          )}
          {imageError && (
            <img src="/images/default-avatar.png" alt="Default Avatar" />
          )}
        </div>
        <h2 className="text-lg font-medium text-gray-700 mb-2">
          {instructor?.name} {instructor?.surname}
        </h2>
        <p className="text-gray-500 text-sm">
          {instructor?.job || "Bilinmiyor"}
        </p>
        <p className="text-gray-400 text-sm italic">
          {instructor?.cv?.profession || "Meslek bilgisi yok"}
        </p>
      </div>

      <div className="flex justify-between p-4 bg-gray-50 text-gray-700">
        <div className="flex flex-col items-center">
          <span className="font-semibold">Eğitmen Puanı</span>
          <span className="text-indigo-600">{averageRating || "N/A"}</span>
        </div>
        <div className="flex flex-col items-center">
          <span className="font-semibold">Öğrenci Sayısı</span>
          <span className="text-indigo-600">{studentCount || "N/A"}</span>
        </div>
      </div>

      <div className="p-4">
        <h3 className="text-lg font-semibold mb-2 text-gray-800">Biyografi</h3>
        <p className="text-sm text-gray-600">
          {instructor?.cv?.bio || "Biyografi mevcut değil."}
        </p>
      </div>
    </div>
  );
};
