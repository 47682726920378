import { getByContact, remove } from "@/actions/address.action";
import { UserContext } from "@/context/UserContext";
import { AddressDto } from "@/data/dtos/address.dto";
import { url } from "@/utils";
import { Modal, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CreateAddress from "./CreateAddress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

export default function Addresses() {
  const dispatch = useDispatch();
  const { addresses } = useSelector((state: any) => state.addresses);
  const userContext = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [shippingAddress, setShippingAddress] = useState<any>([]);
  const [billingAddress, setBillingAddress] = useState<any>([]);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  const { user } = userContext;

  useEffect(() => {
    dispatch(getByContact(user?._id!) as any);
  }, [dispatch]);

  useEffect(() => {
    if (addresses) {
      setBillingAddress(
        addresses.filter((a: AddressDto) => a.isBillingAddress) as AddressDto[]
      );
      setShippingAddress(
        addresses.filter((a: AddressDto) => !a.isBillingAddress) as AddressDto[]
      );
    }
  }, [addresses]);

  const handleDelete = async (id: string) => {
    dispatch(remove(id) as any);
  };

  return (
    <div className="container mx-auto my-4 h-svh relative">
      <div className=" flex flex-col">
        <div>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
            Kargo Adresi
          </Typography>
          <div className="grid grid-cols-4 gap-4 my-8">
            {shippingAddress?.map((address: AddressDto, index) => (
              <div
                key={address._id ?? index}
                className="rounded shadow bg-white relative"
              >
                <div className="p-4 flex flex-col">
                  <div className="w-full text-lg">{address.title}</div>
                  <div className="text-gray-500">{address.address}</div>
                  <div className="text-gray-500">{address.contactName}</div>
                  <div className="text-gray-500">
                    {address.isBillingAddress
                      ? "Fatura Adresi"
                      : "Kargo Adresi"}
                  </div>
                </div>
                <div className="w-full flex flex-wrap absolute top-4 right-4 gap-4 justify-end">
                  <button
                    onClick={() => {
                      setIsOpen(!isOpen);
                      setAddressId(address._id!);
                    }}
                    className=" bg-yellow-400 text-white px-2 rounded shadow"
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </button>
                  <button
                    onClick={() => handleDelete(address._id!)}
                    className=" bg-red-400 text-white px-2 rounded shadow"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
            Fatura Adresi
          </Typography>
          <div className="grid grid-cols-4 gap-4 my-8">
            {billingAddress?.map((address: AddressDto, index) => (
              <div
                key={address._id ?? index}
                className="rounded shadow bg-white relative"
              >
                <div className="p-4 flex flex-col">
                  <div className="w-full text-lg">{address.title}</div>
                  <div className="text-gray-500">{address.address}</div>
                  <div className="text-gray-500">{address.contactName}</div>
                  <div className="text-gray-500">
                    {address.isBillingAddress
                      ? "Fatura Adresi"
                      : "Kargo Adresi"}
                  </div>
                </div>
                <div className="w-full flex flex-wrap absolute top-4 right-4 gap-4 justify-end">
                  <button
                    onClick={() => {
                      setIsOpen(!isOpen);
                      setAddressId(address._id!);
                    }}
                    className=" bg-yellow-400 text-white px-2 rounded shadow"
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </button>
                  <button
                    onClick={() => handleDelete(address._id!)}
                    className=" bg-red-400 text-white px-2 rounded shadow"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="absolute top-4 right-4">
        <button
          onClick={() => {
            setIsOpen(!isOpen);
            setAddressId("");
          }}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Yeni Adres Ekle
        </button>
      </div>
      <Modal open={isOpen} className="p-4 my-4">
        <div className="container mx-auto mt-4 relative bg-white rounded shadow ">
          <CreateAddress id={addressId} />
          <button
            className="absolute top-4 right-4"
            onClick={() => setIsOpen(!isOpen)}
          >
            X
          </button>
        </div>
      </Modal>
    </div>
  );
}
