import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAll, getAllAuth } from "../../../../../actions/courses.actions";
import { CourseDto } from "@/data/dtos/course.dto";
import CreateCourseCard from "@/components/auth/admin/courses/CreateCourseCard";
import CourseTable from "@/components/auth/admin/courses/CourseTable";
import { CourseStatus } from "@/data/enums/course-status.enum";
import Skeleton from "react-loading-skeleton";

export default function AllCourses() {
  const dispatch = useDispatch();
  const { courses, listStatus } = useSelector((state: any) => state.courses);
  const { user } = useSelector((state: any) => state.auth);
  const [coursesByInstructor, setCoursesByInstructor] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const fetchCourses = async () => {
      if (isMounted) {
        dispatch(getAllAuth() as any);
      }
      setLoading(false);
    };
    fetchCourses();
    return () => {
      isMounted = false;
    };
  }, [dispatch, listStatus, user._id, user.role]);

  useEffect(() => {
    console.log(courses);
    if (courses?.length > 0) {
      let filteredCourses = courses?.filter(
        (course: CourseDto) => course.isOnSale
      );
      console.log("filtered courses", filteredCourses);
      setCoursesByInstructor(filteredCourses);
    }
  }, [courses]);
  if (loading) {
    return <Skeleton height={400} />;
  }

  if (coursesByInstructor.length > 0) {
    const rows = coursesByInstructor.map((item: CourseDto, index) => ({
      id: item._id,
      no: index + 1, // Her satıra benzersiz bir ID
      title: item.title,
      saleStart: item.startDate,
      level: item.level,
      price: item.price,
      discount: item.price * (item.discountPercentage / 100),
      tax: item.tax,
      status: item.status,
      isOnSale: item.isOnSale,
      thumbnail: item.images?.[0].url,
      categories: item.categories,
    }));

    return <CourseTable tableTitle="Aktif Atölyeler" data={rows} />;
  }
  return <CreateCourseCard title="Atölye bulunamadı" cta="Atölye Ekle" />;
}
