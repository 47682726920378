import { InvoiceDto } from "@/data/dtos/invoice.dto";
import { logger } from "@/lib/default-logger";

interface InvoiceState {
  invoices: InvoiceDto[];
  currentInvoice: InvoiceDto | null;
  status: string;
  listStatus: string;
}

const initialState: InvoiceState = {
  currentInvoice: null,
  invoices: [],
  status: "idle",
  listStatus: "idle",
};

export default function invoiceReducer(state = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case "FETCH_INVOICES_SUCCESS":
      return {
        ...state,
        invoices: payload,
        listStatus: "success",
      };
    case "FETCH_INVOICE_SUCCESS":
      return {
        ...state,
        currentInvoice: payload,
        status: "success",
      };
    case "CREATE_INVOICE_SUCCESS":
      return {
        ...state,
        invoices: [...state.invoices, payload.data],
        currentInvoice: payload.data,
        status: "success",
      };
    case "UPDATE_INVOICE_SUCCESS":
      return {
        ...state,
        invoices: state.invoices.map((invoice) =>
          invoice._id === payload.data._id ? { ...payload.data } : invoice
        ),
        currentInvoice: { ...payload.data }, // Eğer başka bir yerde currentInvoice referansı kullanılıyorsa
        status: "success",
      };
    case "DELETE_INVOICE_SUCCESS":
      return {
        ...state,
        invoices: state.invoices.filter(
          (invoice) => invoice._id !== payload.data._id
        ),
        currentInvoice: null,
        status: "success",
      };
    case "CREATE_INVOICE_FAIL":
    case "UPDATE_INVOICE_FAILED":
    case "DELETE_INVOICE_FAILED":
    case "FETCH_INVOICES_FAILED":
    case "FETCH_INVOICE_FAILED":
      return {
        ...state,
        status: "failed",
      };
    default:
      return state;
  }
}
