import { url } from "@/utils";
import React from "react";
import { Link } from "react-router-dom";

export default function CreateCourseCard({ title, cta }) {
  return (
    <div>
      <div className="text-center p-4 bg-gray-200  rounded shadow flex flex-col gap-4">
        <span className="font-bold text-lg text-gray-600">{title}</span>
        <Link
          className="text-blue-600 rounded border border-blue-500 shadow p-4 hover:bg-blue-500 hover:text-white"
          to={url("Admin.Courses.Create")}
        >
          {cta}
        </Link>
      </div>
    </div>
  );
}
