import React, { useRef, useEffect } from "react";
import Card from "../core/Card";

const CardSlider = ({ selectedCourse }: any) => {
  const scrollContainerRef = useRef<HTMLUListElement>(null);
  const touchStartX = useRef<number | null>(null);
  const touchEndX = useRef<number | null>(null);

  const scroll = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = scrollContainerRef.current.offsetWidth / 1.5; // Dynamic scroll based on container width
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    touchStartX.current = event.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current === null || touchEndX.current === null) return;
    const swipeDistance = touchStartX.current - touchEndX.current;
    if (swipeDistance > 50) scroll("right"); // Swipe left
    if (swipeDistance < -50) scroll("left"); // Swipe right
    touchStartX.current = null;
    touchEndX.current = null;
  };

  const handleTouchMove = (event: React.TouchEvent) => {
    touchEndX.current = event.touches[0].clientX;
  };

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (
        scrollContainerRef.current &&
        document.activeElement === scrollContainerRef.current
      ) {
        if (event.key === "ArrowLeft") scroll("left");
        if (event.key === "ArrowRight") scroll("right");
      }
    };

    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, []);

  return (
    <div className="relative">
      {/* Left Scroll Button */}
      <button
        onClick={() => scroll("left")}
        aria-label="Scroll Left"
        className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-3 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-700 hidden sm:block"
      >
        &lt; {/* Left Arrow */}
      </button>

      {/* Scrollable Container */}
      <ul
        ref={scrollContainerRef}
        className="overflow-hidden whitespace-nowrap flex gap-4 relative px-4 scroll-snap-x scroll-snap-mandatory sm:scroll-auto"
        tabIndex={0} // Allows focus for keyboard navigation
        aria-live="polite"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {/* Add gradient effect on edges */}
        <div
          className="absolute inset-y-0 left-0 w-12 bg-gradient-to-r from-[#81BFDA] to-transparent pointer-events-none"
          aria-hidden="true"
        ></div>
        <div
          className="absolute inset-y-0 right-0 w-12 bg-gradient-to-l from-[#81BFDA] to-transparent pointer-events-none"
          aria-hidden="true"
        ></div>

        {selectedCourse?.courses?.length > 0 ? (
          selectedCourse.courses.map((course: any) => (
            <li
              key={course._id}
              className="max-w-[250px] min-w-[250px] sm:max-w-[300px] sm:min-w-[300px] flex-shrink-0 transition-transform hover:scale-105 scroll-snap-start"
            >
              <Card course={course} />
            </li>
          ))
        ) : (
          <div className="text-gray-500 text-center w-full py-8">
            Atölye bulunamadı.
          </div>
        )}
      </ul>

      {/* Right Scroll Button */}
      <button
        onClick={() => scroll("right")}
        aria-label="Scroll Right"
        className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-3 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-700 hidden sm:block"
      >
        &gt; {/* Right Arrow */}
      </button>
    </div>
  );
};

export default React.memo(CardSlider);
