import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import authService from "../services/auth.service";
import { logger } from "../lib/default-logger";
import { UserData } from "@/data/dtos/user.dto";

export interface UserContextValue {
  user: UserData | null;
  error: string | null;
  loading: boolean;
  status: string;
  checkSession?: () => Promise<void>;
}

export const UserContext = React.createContext<UserContextValue | undefined>(
  undefined
);

export const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context.user;
};

export function UserProvider({
  children,
}: {
  readonly children: React.ReactNode;
}) {
  const { token, user: reduxUser } = useSelector((state: any) => state.auth); // Redux'taki user
  const dispatch = useDispatch();

  const [state, setState] = useState<UserContextValue>({
    user: reduxUser, // Başlangıç değeri olarak Redux'taki user
    error: null,
    loading: false,
    status: "idle",
  });

  const tokenRef = useRef(token);
  useEffect(() => {
    tokenRef.current = token;
  }, [token]);

  const checkSession = useCallback(async (): Promise<void> => {
    logger.log("checkSession");

    const currentToken = tokenRef.current;

    try {
      if (currentToken) {
        const response = await authService.updateState(currentToken);
        if (!response) return logger.error("Invalid token");
        const { data } = response;

        dispatch({ type: "UPDATE_STATE", payload: data });
        setState((prev) => ({
          ...prev,
          user: data,
          error: null,
          loading: false,
          status: "success",
        }));
      }
    } catch (error) {
      logger.error(error);
      setState((prev) => ({
        ...prev,
        user: null,
        error: "Something went wrong",
        loading: false,
        status: "failed",
      }));
    }
  }, [dispatch]);

  // Redux user değiştiğinde UserContext'i güncelle
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      user: reduxUser,
    }));
  }, [reduxUser]);

  const value = useMemo(
    () => ({ ...state, checkSession }),
    [state, checkSession]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const UserConsumer = UserContext.Consumer;
