import { get, getMetricsForTeacher } from "@/actions/mertrics.action";
import { getAll } from "@/actions/orders.actions";
import Chart from "@/components/auth/admin/dashboard/Chart";
import LastComments from "@/components/auth/admin/dashboard/LastComments";
import OrdersChart from "@/components/auth/admin/dashboard/OrdersChart";
import ReportCard from "@/components/auth/admin/dashboard/ReportCard";
import { UserContext } from "@/context/UserContext";
import { CourseDto } from "@/data/dtos/course.dto";
import { OrderData } from "@/data/dtos/order.dto";
import { UserData } from "@/data/dtos/user.dto";
import { OrderStatus } from "@/data/enums/order-status.enum";
import {
  faBox,
  faDollar,
  faEye,
  faMessage,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminDashboard from "./AdminDashboard";
import TeacherDashboard from "../teacher/TeacherDashboard";

export default function Dashboard() {
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  const { user } = userContext;

  let dashboardComponent;

  if (user?.role === "admin") {
    dashboardComponent = <AdminDashboard />;
  } else {
    dashboardComponent = <TeacherDashboard />;
  }

  return dashboardComponent;
}
