import { ReviewDto } from "@/data/dtos/review.dto";
import { apiClient } from "./base.service";

const coursesService = {
  create: async (payload: any) => await apiClient.post("/courses", payload),
  getAll: async () => await apiClient.get("/courses"),
  getAllAuth: async () => await apiClient.get("/courses/auth"),
  get: async (id: string) => await apiClient.get("/courses/" + id),
  update: async (id: string, payload: any) =>
    await apiClient.patch("/courses/" + id, payload),
  remove: async (id: string) => await apiClient.delete("/courses/" + id),
  filter: async (payload: any) =>
    await apiClient.post("/courses/filter", payload),
  addSection: async (payload: any) =>
    await apiClient.post("/sections", payload),
  updateSection: async (id: string, payload: any) =>
    await apiClient.patch("/sections/" + id, payload),
  removeSection: async (id: string) =>
    await apiClient.delete("/sections/" + id),
  addResource: async (payload: any) =>
    await apiClient.post("/resources", payload),
  updateResource: async (id: string, payload: any) =>
    await apiClient.patch("/resources/" + id, payload),
  removeResource: async (id: string) =>
    await apiClient.delete("/resources/" + id),
  uploadFile: async (
    payload: FormData,
    onUploadProgress: (progressEvent: any) => void
  ) =>
    await apiClient.post("/resources/upload-file", payload, {
      onUploadProgress,
    }),
  uploadVideo: async (
    payload: any,
    onUploadProgress: (progressEvent: any) => void
  ) => await apiClient.post("/videos/upload", payload, { onUploadProgress }),
  sales: async () => await apiClient.get("/courses/sales"),
  getBySlug: async (slug: string) =>
    await apiClient.get("/courses/slug/" + slug),
  getByInstructer: async (id: string) =>
    await apiClient.get("/courses/instructor/" + id),
  addReview: async (id: string, review: ReviewDto) =>
    await apiClient.patch("/courses/review/" + id, review),
  generateLiveClassToken: async (
    channelName: string,
    uid: number,
    role: "teacher" | "admin" | "student",
    user: string
  ) =>
    await apiClient.get(
      "/courses/agora-token/" +
        channelName +
        "/" +
        uid +
        "/" +
        role +
        "/" +
        user
    ),
  getActiveClasses: async (channelName: string) =>
    await apiClient.get("/courses/channels/" + channelName),
  getUserByUid: async (uid: string) => await apiClient.get("/courses/" + uid),
};

export default coursesService;
