export enum Roles {
  ADMIN = 'admin',
  TEACHER = 'teacher',
  STUDENT = 'student',
  ACCOUNTER = 'accounter',
  MANAGER = 'manager',
  SUPERVISOR = 'supervisor',
  PUBLISHER = 'publisher',
  SUPPORT = 'support',
}
