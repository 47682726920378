import { apiClient } from "./base.service";

const uploadFileService = {
  uploadFile: async (
    payload: FormData,
    onUploadProgress: (progressEvent: any) => void
  ) =>
    await apiClient.post(
      process.env.REACT_APP_API_BASE_URL + "/resources/upload-file",
      payload,
      { onUploadProgress }
    ),
  update: async (
    payload: FormData,
    onUploadProgress: (progressEvent: any) => void
  ) =>
    await apiClient.patch("/resources/update-file", payload, {
      onUploadProgress,
    }),
  remove: async (payload: any) =>
    await apiClient.post("/resources/delete-file", payload),
};

export default uploadFileService;
