import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "@/actions/orders.actions";
import InputLabel from "@/components/InputLabel";
import { useParams } from "react-router-dom";
import { create } from "@/actions/invoice.action";

export default function CreateInvoice() {
  const { id } = useParams();
  console.log(id);
  const dispatch = useDispatch();
  // Form state'lerini oluşturuyoruz
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [installmentCount, setInstallmentCount] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [order, setOrder] = useState(id);
  const { orders, status } = useSelector((state: any) => state.order);

  useEffect(() => {
    if (status === "idle" && orders.length === 0) {
      console.log("useEffect 1");
      dispatch(getAll() as any);
    }
  }, [dispatch, status, orders]);

  // Form submit işlemi
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Frontend tarafındaki InvoiceDto ile uyumlu veri yapısı oluşturuyoruz
    const invoiceData = {
      invoiceNumber,
      paymentType,
      paymentStatus,
      order,
      installmentCount,
    };

    console.log("Invoice Data:", invoiceData);
    dispatch(create(invoiceData) as any);
    // Burada API çağrısı yapabilir veya başka işlemler gerçekleştirebilirsiniz.
  };

  return (
    <div className="w-full bg-white rounded p-8 min-h-screen">
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        autoComplete="off"
        sx={{
          maxWidth: 400,
          margin: "2rem auto",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="h5" component="h1" align="center">
          Fatura oluştur
        </Typography>
        <TextField
          label="Fatura No"
          variant="outlined"
          value={invoiceNumber}
          onChange={(e) => setInvoiceNumber(e.target.value)}
          required
        />
        <TextField
          label="Ödeme Kanalı"
          variant="outlined"
          value={paymentType}
          onChange={(e) => setPaymentType(e.target.value)}
          required
        />
        <TextField
          label="Ödeme Şekli"
          variant="outlined"
          value={installmentCount}
          onChange={(e) => setInstallmentCount(e.target.value)}
          required
        />
        <TextField
          label="Ödeme Durumu"
          variant="outlined"
          value={paymentStatus}
          onChange={(e) => setPaymentStatus(e.target.value)}
          required
        />

        <Button type="submit" variant="contained" color="primary">
          Fatura OLUŞTUR
        </Button>
      </Box>
    </div>
  );
}
