import { get, getMetricsForTeacher } from "@/actions/mertrics.action";
import { getAll } from "@/actions/orders.actions";
import Chart from "@/components/auth/admin/dashboard/Chart";
import LastComments from "@/components/auth/admin/dashboard/LastComments";
import OrdersChart from "@/components/auth/admin/dashboard/OrdersChart";
import ReportCard from "@/components/auth/admin/dashboard/ReportCard";
import { UserContext } from "@/context/UserContext";
import { OrderData } from "@/data/dtos/order.dto";
import { UserData } from "@/data/dtos/user.dto";
import { OrderStatus } from "@/data/enums/order-status.enum";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InvoiceList from "./invoices/InvoiceList";

export default function AdminDashboard() {
  const dispatch = useDispatch();
  const userContext = useContext(UserContext);
  const [relatedOrders, setRelatedOrders] = useState<OrderData[]>([]);
  const { metrics, status } = useSelector((state: any) => state.metrics);
  const { orders, listStatus } = useSelector((state: any) => state.order);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  const { user } = userContext;

  // Fetch orders if listStatus is idle
  useEffect(() => {
    if (listStatus === "idle") {
      dispatch(getAll() as any);
    }
  }, [listStatus, dispatch]);

  useEffect(() => {
    if (status === "idle") {
      if (user?.role === "teacher") {
        dispatch(getMetricsForTeacher(user._id!) as any);
      } else if (user?.role === "admin") {
        dispatch(get() as any);
      }
    }
  }, [status, dispatch]);

  // Filter related orders based on user role
  useEffect(() => {
    const filterRelatedOrders = () => {
      if (!orders.length || !user?._id) return [];

      if (user.role === "teacher") {
        return orders?.filter(
          (order: OrderData) =>
            order.status === OrderStatus.PAID &&
            order.items?.some((item: any) =>
              item.course.instructors?.includes(user._id)
            )
        );
      }

      // Return all orders for non-teacher roles
      return orders;
    };

    setRelatedOrders(filterRelatedOrders());
  }, [orders, user]);

  // Chart data
  const data = metrics?.weeklyWageList;

  const rows = relatedOrders?.map((order: any, index) => ({
    id: order._id,
    no: index + 1,
    buyer: order.buyer,
    email: (order.buyer as UserData)?.email,
    phone: (order.buyer as UserData)?.phone,
    price: order,
    paidPrice: order,
    paidPriceWithTax: order,
    status: order.status,
    createdAt: order.createdAt,
  }));

  console.log(rows);

  return (
    <div className="container mx-auto rounded bg-white flex flex-col p-4">
      <h1 className="justify-center w-full text-center text-xl font-semibold">
        Hoş Geldiniz, {user?.name ?? "Guest"}!
      </h1>
      {/* Metrics Grid */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 my-4">
        {metrics?.metrics?.map((metric) => (
          <ReportCard metric={metric} key={metric.id} />
        ))}
      </div>
      {/* Chart Section */}
      <Chart data={data} />
      {/* Orders Section */}
      <OrdersChart recentOrders={rows} />
      <div className="my-4">
        <h2 className="text-xl font-semibold">Faturalar</h2>
        <InvoiceList />
      </div>
      <section className="grid grid-cols-1 md:grid-cols-2 mt-4">
        <div>Son Satılan Kurslar</div>
        <div>
          <LastComments />
        </div>
      </section>
    </div>
  );
}
