import { AddressDto } from "@/data/dtos/address.dto";
import axios from "axios";
import { apiClient } from "./base.service";

const addressService = {
  create: async (payload: AddressDto) =>
    await apiClient.post("/addresses", payload),
  getAll: async () => await apiClient.get("/addresses"),
  get: async (id: string) => await apiClient.get("/addresses/" + id),
  update: async (id: string, payload: AddressDto) =>
    await apiClient.patch("/addresses/" + id, payload),
  remove: async (id: string) => await apiClient.delete("/addresses/" + id),
  findByContact: async (id: string) =>
    await apiClient.get("/addresses/contact/" + id),
};

export default addressService;
