import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { addReview, get } from "../../../actions/courses.actions";
import {
  Box,
  Typography,
  Modal,
  Button,
  Rating,
  TextField,
} from "@mui/material";
import QuizComponent from "./QuizComponent";
import VideoComponent from "./VideoComponent";
import CourseSection from "./CourseSection";
import { ResourceType } from "@/data/enums/resource-type.enum";
import { ResourceDto } from "@/data/dtos/resource.dto";
import LiveClassComponent from "./liveClassComponents/LiveClassComponent";
import { ReviewDto } from "@/data/dtos/review.dto";
import { UserContext } from "@/context/UserContext";
import Header from "@/components/core/Header";
import { CourseDto } from "@/data/dtos/course.dto";
import { FaFile } from "react-icons/fa";
import {
  markResourceAsComplated,
  updateCourseProgress,
} from "@/actions/authAction";
import { getSectionsByCourse } from "@/actions/serctions.action";
import { set } from "lodash";

export default function Course() {
  const userContext = useContext(UserContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { sections } = useSelector((state: any) => state.sections);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  const { user } = userContext;
  console.log(user);
  const [courseDetail, setCourseDetail] = useState<CourseDto | null>(null);
  const [userCourse, setUserCourse] = useState<any>(null);
  const [currentSections, setCurrentSections] = useState([]);
  const [currentSection, setCurrentSection] = useState<any>(null);
  const [currentResource, setCurrentResource] = useState<ResourceDto | null>(
    null
  );
  const [courseProgress, setCourseProgress] = useState([]);
  console.log(courseProgress);

  const [review, setReview] = useState<ReviewDto>({
    comment: "",
    rating: 0,
    user: user?._id || "",
    isVerified: false,
    likeAndDislike: { like: 0, dislike: 0 },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (Array.isArray(user?.userCourseProgress) && user?.userCourseProgress) {
      const foundCourse = user?.userCourseProgress?.find(
        (detail: any) => detail.course._id === id
      ) as any;
      if (foundCourse) {
        setCourseDetail(foundCourse.course as CourseDto);
      }
    }
  }, [user, id]);

  useEffect(() => {
    if (courseDetail) {
      setCurrentResource(courseDetail.sections?.[0]?.resources?.[0] || null);
      setCurrentSection(courseDetail.sections?.[0] || null);
    }
  }, [courseDetail]);

  useEffect(() => {
    dispatch(getSectionsByCourse(courseDetail?._id!) as any);
  }, [dispatch, courseDetail?._id]);

  useEffect(() => {
    setCurrentSections(sections);
  }, [sections]);

  useEffect(() => {
    setCurrentSection(currentSections[0]);
  }, [currentSections]);

  useEffect(() => {
    console.log("user state updated");
    if (user?.userCourseProgress) {
      const foundCourse = (user?.userCourseProgress as any)?.find(
        (detail: any) => {
          // setting user course progress
          return detail.course._id === courseDetail?._id;
        }
      );
      console.log("foundCourse", foundCourse);
      setUserCourse(foundCourse);
    }
  }, [user, courseDetail?._id]);

  useEffect(() => {
    setIsModalOpen(!userCourse?.hasReviewed); // İnceleme durumu
  }, [userCourse]);

  useEffect(() => {
    if (userCourse) {
      setCourseProgress(userCourse.completedSections);
    }
  }, [userCourse]);

  const handleReviewSubmit = () => {
    if (!courseDetail?._id) return;

    dispatch(addReview(courseDetail._id, review) as any);
    dispatch(
      updateCourseProgress(user?._id!, courseDetail._id, {
        hasReviewed: true,
      }) as any
    );
    setIsModalOpen(false);
  };

  const handleResourceComplete = (successRate: number) => {
    console.log(courseDetail, currentSection, currentResource);
    if (!courseDetail || !currentSection || !currentResource) return;

    dispatch(
      markResourceAsComplated(
        user?._id!,
        courseDetail._id!,
        currentSection._id,
        currentResource._id!,
        { resourceId: currentResource._id, successRate, isCompleted: true }
      ) as any
    );
  };

  const getResourceComponent = () => {
    if (!currentResource) {
      return <Typography>Lütfen bir kaynak seçin.</Typography>;
    }

    const sectionProgress: any = courseProgress.find(
      (section: any) => section.sectionId === currentSection?._id
    );

    const resourceProgress = sectionProgress?.resources.find(
      (resource: any) => resource.resourceId === currentResource?._id
    );

    const isCompleted = resourceProgress?.isCompleted ?? false;
    const successRate = resourceProgress?.successRate ?? 0;

    switch (currentResource.type) {
      case ResourceType.VIDEO:
        return (
          <VideoComponent
            onComplete={handleResourceComplete}
            currentResource={currentResource}
          />
        );
      case ResourceType.QUIZZ:
        return (
          <QuizComponent
            onComplete={handleResourceComplete}
            quiz={currentResource.quizz!}
            isCompleted={isCompleted}
            successRate={successRate}
          />
        );
      case ResourceType.LIVE_CLASS:
        return <LiveClassComponent resource={currentResource} />;
      default:
        return <Typography>Geçersiz kaynak türü.</Typography>;
    }
  };

  return (
    <div className="bg-white rounded shadow w-full flex flex-col gap-2 relative">
      {/* Review Modal */}
      {/* //TODO: check course update when user failed on test some thing wrong when updating  */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="review-modal-title"
        aria-describedby="review-modal-description"
      >
        <Box className="container mx-auto mt-4 relative bg-white rounded shadow p-4">
          <Typography id="review-modal-title" variant="h6">
            Atölyeyi Puanlayın
          </Typography>
          <TextField
            label="Yorumunuz"
            fullWidth
            multiline
            rows={4}
            value={review.comment}
            onChange={(e) =>
              setReview((prev) => ({ ...prev, comment: e.target.value }))
            }
            sx={{ mt: 2 }}
          />
          <Typography sx={{ mt: 2 }}>Puanlama:</Typography>
          <Rating
            name="rating"
            value={review.rating}
            onChange={(event, newValue) =>
              setReview((prev) => ({ ...prev, rating: newValue || 0 }))
            }
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button variant="outlined" onClick={() => setIsModalOpen(false)}>
              Kapat
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleReviewSubmit}
            >
              Gönder
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Header */}
      <Header title={courseDetail?.title || "Seçili Kaynak Yok"} />

      {/* Course Progress Bar */}

      <section
        className="absolute right-2 top-2"
        style={{ width: "50px", height: "50px" }}
      >
        <div className="flex items-center justify-center relative">
          <svg
            className="transform -rotate-90"
            width="50"
            height="50"
            viewBox="0 0 36 36"
          >
            <circle
              cx="18"
              cy="18"
              r="15"
              stroke="currentColor"
              strokeWidth="4"
              fill="transparent"
              className="text-gray-700"
            />
            <circle
              cx="18"
              cy="18"
              r="15"
              stroke="currentColor"
              strokeWidth="4"
              fill="transparent"
              strokeDasharray={2 * Math.PI * 15}
              strokeDashoffset={
                2 * Math.PI * 15 -
                (userCourse?.progress / 100) * (2 * Math.PI * 15)
              }
              className="text-blue-500"
            />
          </svg>
          <span className="absolute text-sm font-bold">{`${userCourse?.progress}%`}</span>
        </div>
      </section>

      <div className="w-full flex md:flex-row flex-col px-2 md:px-0 gap-2 justify-between">
        <div className="w-full">
          <Box className="container mx-auto rounded border">
            {getResourceComponent()}
          </Box>
        </div>
        <div>
          <CourseSection
            sections={currentSections}
            courseProgress={userCourse?.completedSections}
            setCurrentResource={setCurrentResource}
            setCurrentSection={setCurrentSection}
          />
        </div>
      </div>
      <hr />
      <Box className="w-full py-8 container mx-auto">
        <Typography variant="h5" gutterBottom>
          {courseDetail?.title}
        </Typography>
        <Typography color="textSecondary">
          {courseDetail?.description}
        </Typography>
        <Typography variant="h6" sx={{ mt: 4 }}>
          Atölye Dokümanları
        </Typography>
        <Box className="grid grid-cols-8 gap-4">
          {courseDetail?.documents?.map((document) => (
            <Link key={document._id} to={document.url} target="_blank">
              <Box className="rounded p-4 relative hover:scale-105">
                <FaFile className="w-40 h-40" />
                <Typography className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">
                  {document.fileName}
                </Typography>
              </Box>
            </Link>
          ))}
        </Box>
      </Box>
    </div>
  );
}
