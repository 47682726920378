import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navigation from "../layouts/Navigation";
import AdminSideMenu from "../../../../components/auth/admin/AdminSideMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "@/context/UserContext";

export default function AdminLayout() {
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  const { error, loading, checkSession } = userContext;

  useEffect(() => {
    console.log("checkSession");
    checkSession?.();
  }, [checkSession]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div>
      <div>
        <Navigation />
      </div>
      <div className="flex flex-wrap w-full">
        <div className="bg-white w-1/4">
          <AdminSideMenu />
        </div>
        <div className="bg-gray-100 p-4 rounded-xl w-full md:w-3/4">
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
}
