import { setMessage } from "./message";

export const addToWhistList = (payload: any) => async (dispatch: any) => {
  dispatch({ type: "ADD_TO_WHIST_LIST", payload: payload });
};

export const removeWhistList = (payload: any) => async (dispatch: any) => {
  dispatch({ type: "REMOVE_FROM_WHIST_LIST", payload: payload });
  dispatch(setMessage("Atölye İstek Listesinden Kaldırılıdı.", 5000));
};

export const clearWhistList = () => async (dispatch: any) => {
  dispatch({ type: "CLEAR_WHIST_LIST" });
  dispatch(setMessage("İstek Listesi Temizlendi", 5000));
};
