import { remove } from "@/actions/courses.actions";
import OptionsMenu from "@/components/core/TableOptionMenu";
import { CourseStatus } from "@/data/enums/course-status.enum";
import { url } from "@/utils";
import { Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function CourseTable({ tableTitle, data }) {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const handleDelete = async (id) => {
    dispatch(remove(id) as any);
  };
  const columns: GridColDef[] = [
    {
      field: "no",
      headerName: "No",
      flex: 0.1,
    },
    {
      field: "thumbnail",
      headerName: "Atölye Görseli",
      flex: 0.2,
      renderCell: (params) => (
        <img
          src={params.value}
          alt={params.row.title}
          style={{ width: "100px", height: "100px" }}
        />
      ),
    },
    { field: "title", headerName: "Atölye Adı", flex: 0.5 },
    {
      field: "categories",
      headerName: "Kategoriler",
      flex: 0.5,
      renderCell: (params) => (
        <div>
          {params.row.categories.map((category: any) => (
            <span key={category._id} style={{ marginRight: "5px" }}>
              {category.name}
            </span>
          ))}
        </div>
      ),
    },
    {
      field: "saleStart",
      headerName: "Satış Baslangıc Tarihi",
      flex: 0.3,
      renderCell: (params) => {
        console.log(params);
        return (
          <span>
            {params.row.saleStart
              ? new Date(params.row.saleStart).toLocaleDateString("tr-TR")
              : ""}
          </span>
        );
      },
    },
    {
      field: "status",
      headerName: "Atölye Durumu",
      flex: 0.2,
      renderCell: (params) => {
        switch (params.value) {
          case CourseStatus.ARCHIVED:
            return (
              <span className="bg-red-600 text-white p-2 rounded-md">
                Arşivlendi
              </span>
            );
          case CourseStatus.PUBLISHED:
            return (
              <span className="bg-green-600 text-white p-2 rounded-md">
                Yayında
              </span>
            );
          case CourseStatus.DRAFT:
            return (
              <span className="bg-gray-600 text-white p-2 rounded-md">
                Taslak
              </span>
            );

          case CourseStatus.PENDING:
            return (
              <span className="bg-yellow-600 text-white p-2 rounded-md">
                Onay Bekliyor
              </span>
            );

          case CourseStatus.REJECTED:
            return (
              <span className="bg-red-600 text-white p-2 rounded-md">
                Reddedildi
              </span>
            );

          default:
            break;
        }
      },
    },
    {
      field: "options",
      headerName: "Seçenekler",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation(); // Prevent row selection on click
          // You can manage the menu state in the parent or directly in the component
        };

        return (
          <Stack direction="row" spacing={1}>
            <OptionsMenu
              id={params.row.id}
              onEdit={() => {
                navigation(url("Admin.Courses.Edit", { id: params.row.id }));
              }}
              onDelete={handleDelete}
              onView={() => {
                navigation(url("Admin.Courses.View", { id: params.row.id }));
              }}
              onOpenMenu={handleMenuOpen} // Pass the handler to OptionsMenu
            />
          </Stack>
        );
      },
    },
  ];

  const pageSizeOptions = [25, 50, 100];

  return (
    <div className="bg-white rounded shadow p-4">
      <Typography className="w-full text-center my-4 font-bold">
        {tableTitle}
      </Typography>
      <div className="h-auto w-full p-2">
        <DataGrid
          rows={data}
          columns={columns}
          pageSizeOptions={pageSizeOptions}
          checkboxSelection
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: pageSizeOptions[0] },
            },
          }}
        />
      </div>
    </div>
  );
}
