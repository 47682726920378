import { CategoryData } from "@/data/dtos/category.dto";
import { apiClient } from "./base.service";

const categoriesService = {
  getAll: async () => await apiClient.get("/categories"),
  get: async (id: string) => await apiClient.get("/categories/" + id),
  create: async (payload: CategoryData) =>
    await apiClient.post("/categories", payload),
  update: async (id: string, payload: CategoryData) =>
    await apiClient.patch("/categories/" + id, payload),
  remove: async (id: string) => await apiClient.delete("/categories/" + id),
};

export default categoriesService;
