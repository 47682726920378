import { setError } from "@/actions/message";
import { get, update } from "@/actions/users.actions";
import UploadSection from "@/components/auth/admin/UploadSection";
import InputError from "@/components/core/InputError";
import { UserData } from "@/data/dtos/user.dto";
import { Roles } from "@/data/enums/roles.enum";
import { UserStatus } from "@/data/enums/user-status.enum";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export default function UpdateUser() {
  //TODO fix the loop
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { id } = useParams();
  const { currentUser } = useSelector((state: any) => state.users);
  const { currentImage } = useSelector((state: any) => state.images);
  const { message } = useSelector((state: any) => state.message);
  const [formData, setFormData] = useState<UserData>(
    currentUser || {
      name: "",
      surname: "",
      email: "",
      role: "student",
      profileImage: "",
      status: "active",
      phone: "+90",
    }
  );

  useEffect(() => {
    if (currentUser) {
      const {
        _id,
        __v,
        resetToken,
        createdAt,
        updatedAt,
        addresses,
        billingAddresses,
        courses,
        orders,
        ...filteredData
      } = currentUser;
      setFormData(filteredData);
    }
  }, [currentUser]);

  useEffect(() => {
    if (id) {
      dispatch(get(id) as any);
    }
  }, [dispatch, id]);

  const handleSubmit = () => {
    console.log(formData);
    if (id) {
      dispatch(update(id, formData) as any);
    } else {
      dispatch(setError("Kullanıcı bulunamadı"));
    }
  };

  return (
    <div className="bg-white rounded shadow w-full p-4">
      {message?.message && (
        <div className="flex flex-col gap-4 rounded bg-red-500 p-4 text-white">
          {message.message}
        </div>
      )}
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}
      >
        <Typography variant="h6">Update User</Typography>
        <div className="grid grid-cols-4 gap-4 items-center">
          <div className="flex flex-col">
            <TextField
              label="Name"
              variant="outlined"
              value={formData.name}
              onChange={(e) => {
                setFormData({ ...formData, name: e.target.value });
              }}
            />
            <InputError messages={message?.errors?.name} className="mt-2" />
          </div>
          <div className="flex flex-col">
            <TextField
              label="Surname"
              variant="outlined"
              value={formData.surname}
              onChange={(e) => {
                setFormData({ ...formData, surname: e.target.value });
              }}
            />
            <InputError messages={message?.errors?.surname} className="mt-2" />
          </div>
          <div className="flex flex-col">
            <TextField
              label="Email"
              variant="outlined"
              value={formData.email}
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value });
              }}
            />
            <InputError messages={message?.errors?.email} className="mt-2" />
          </div>
          <div className="flex flex-col">
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              value={formData.password}
              onChange={(e) => {
                setFormData({ ...formData, password: e.target.value });
              }}
            />
            <InputError messages={message?.errors?.password} className="mt-2" />
          </div>
          <div className="flex flex-col col-span-2">
            <TextField
              label="Identity number"
              variant="outlined"
              value={formData.identityNumber}
              className="col-span-2"
              onChange={(e) => {
                setFormData({ ...formData, identityNumber: e.target.value });
              }}
            />
            <InputError
              messages={message?.errors?.identityNumber}
              className="mt-2"
            />
          </div>
          <div className="flex flex-col col-span-2">
            <MuiTelInput
              label="Phone"
              variant="outlined"
              className="col-span-2"
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e })}
            />
            <InputError messages={message?.errors?.phone} className="mt-2" />
          </div>
          <div className="col-span-2">
            <InputLabel id="user-status">Status</InputLabel>
            <Select
              labelId="user-status"
              id="user-status"
              value={formData.status}
              variant="outlined"
              fullWidth
              label="Status"
              onChange={(e) => {
                setFormData({ ...formData, status: e.target.value });
              }}
            >
              {Object.values(UserStatus).map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            <InputError messages={message?.errors?.status} className="mt-2" />
          </div>
          <div className="col-span-2 flex flex-col">
            <InputLabel id="user-role">Role</InputLabel>
            <Select
              labelId="user-role"
              id="user-role"
              value={formData.role}
              variant="outlined"
              fullWidth
              label="Role"
              onChange={(e) => {
                setFormData({ ...formData, role: e.target.value });
              }}
            >
              {Object.values(Roles).map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            <InputError messages={message?.errors?.role} className="mt-2" />
          </div>
          <div className="col-span-4 flex flex-col">
            <UploadSection
              images={[
                formData?.profileImage ? { url: formData?.profileImage } : null,
              ]}
              title="Profile Image"
              onChange={(field: string, value: any) => {
                console.log(value.url, field);
                setFormData({ ...formData, profileImage: currentImage?.url });
              }}
            />
            <InputError
              messages={message?.errors?.profileImage}
              className="mt-2"
            />
          </div>
        </div>
        <Button
          sx={{ mt: 2, width: "5rem" }}
          color="warning"
          variant="contained"
          onClick={handleSubmit}
        >
          Update
        </Button>
      </Box>
    </div>
  );
}
