import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Chip,
  IconButton,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react"; // TinyMCE gibi bir HTML editor kullanılabilir.
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { AgreementsDto } from "@/data/dtos/agreements.dto";
import { useDispatch } from "react-redux";
import { create } from "@/actions/agreements.action";

export default function CreateAgreement() {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState<AgreementsDto>({
    title: "",
    content: "",
    version: 0,
    dynamicFields: { title: "value", title2: "value2" },
  });

  const [dynamicFieldKey, setDynamicFieldKey] = useState("");
  const [dynamicFieldValue, setDynamicFieldValue] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleEditorChange = (content: string) => {
    setFormValues({ ...formValues, content });
  };

  const handleAddDynamicField = () => {
    if (dynamicFieldKey.trim() && dynamicFieldValue.trim()) {
      setFormValues({
        ...formValues,
        dynamicFields: {
          ...formValues.dynamicFields,
          [dynamicFieldKey]: dynamicFieldValue,
        },
      });
      setDynamicFieldKey("");
      setDynamicFieldValue("");
    }
  };

  const handleDeleteDynamicField = (key: string) => {
    const updatedFields = { ...formValues.dynamicFields };
    delete updatedFields[key];
    setFormValues({ ...formValues, dynamicFields: updatedFields });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(create(formValues) as any);
    // Form gönderme işlemi yapılabilir.
  };

  return (
    <div className=" rounded bg-white shadow p-4">
      <Box sx={{ maxWidth: 600, mx: "auto", mt: 4, p: 2, boxShadow: 3 }}>
        <Typography variant="h4" gutterBottom>
          Sözleşme Oluştur
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Başlık"
                name="title"
                value={formValues.title}
                onChange={handleChange}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                İçerik
              </Typography>
              <Editor
                apiKey="8s9qbest1smxmf3nzha41zb1sovfc1id30qz7oqb9m8wt1fi" // TinyMCE API anahtarını buraya ekleyin.
                value={formValues.content}
                init={{
                  height: 300,
                  menubar: false,
                  plugins: ["lists link image table code"],
                  toolbar:
                    "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code",
                }}
                onEditorChange={handleEditorChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Versyon"
                name="version"
                value={formValues.version}
                onChange={handleChange}
                required
                type="number"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Dinamik Alanlar
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    label="Alan Anahtarı"
                    value={dynamicFieldKey}
                    onChange={(e) => setDynamicFieldKey(e.target.value)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    label="Alan Değeri"
                    value={dynamicFieldValue}
                    onChange={(e) => setDynamicFieldValue(e.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    color="primary"
                    onClick={handleAddDynamicField}
                    aria-label="add field"
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Box mt={2}>
                {Object.entries(formValues.dynamicFields).map(
                  ([key, value]) => (
                    <Chip
                      key={key}
                      label={`${key}: ${value}`}
                      onDelete={() => handleDeleteDynamicField(key)}
                      deleteIcon={<DeleteIcon />}
                      sx={{ m: 0.5 }}
                    />
                  )
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" fullWidth>
                Kaydet
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
}
