import { apiClient } from "./base.service";

const settingService = {
  create: async (payload: any) =>
    await apiClient.post(
      process.env.REACT_APP_API_BASE_URL + "/settings",
      payload
    ),
  getAll: async () =>
    await apiClient.get(process.env.REACT_APP_API_BASE_URL + "/settings"),
  get: async (key: string) =>
    await apiClient.get(
      process.env.REACT_APP_API_BASE_URL + "/settings/" + key
    ),
  update: async (key: string, payload: any) =>
    await apiClient.patch(
      process.env.REACT_APP_API_BASE_URL + "/settings/" + key,
      payload
    ),
  remove: async (key: string) =>
    await apiClient.delete(
      process.env.REACT_APP_API_BASE_URL + "/settings/" + key
    ),
};

export default settingService;
