import { CourseDto } from "@/data/dtos/course.dto";

interface CartState {
  items: any[];
  total: number;
  paidTotal: number;
  tax: number;
  discount: number;
  status: string;
}

const initialState: CartState = {
  items: [],
  total: 0,
  paidTotal: 0,
  tax: 0,
  discount: 0,
  status: "idle",
};

export default function cartReducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case "ADD_TO_CART": {
      const updatedItems = [...state.items, payload];

      const totalPrice = updatedItems.reduce(
        (acc, item: CourseDto) => acc + item.price,
        0
      );

      const totalTax = updatedItems.reduce(
        (acc, item: CourseDto) =>
          acc +
          ((item.price - (item.price * (item.discountPercentage || 0)) / 100) *
            (item.tax ?? 0)) /
            100,
        0
      );

      const totalDiscount = updatedItems.reduce(
        (acc, item: CourseDto) =>
          acc + (item.price * (item.discountPercentage || 0)) / 100,
        0
      );

      return {
        ...state,
        items: updatedItems,
        total: totalPrice,
        tax: totalTax,
        discount: totalDiscount,
        paidTotal: totalPrice - totalDiscount + totalTax,
      };
    }

    case "REMOVE_FROM_CART": {
      const itemToRemove = state.items.find(
        (item: CourseDto) => item._id === payload
      );
      if (!itemToRemove) {
        console.warn(`Item with ID ${payload} not found in cart.`);
        return state;
      }

      const updatedItems = state.items.filter(
        (item: CourseDto) => item._id !== payload
      );

      const totalPrice = updatedItems.reduce(
        (acc, item: CourseDto) => acc + item.price,
        0
      );

      const totalTax = updatedItems.reduce(
        (acc, item: CourseDto) =>
          acc +
          ((item.price - (item.price * (item.discountPercentage || 0)) / 100) *
            (item.tax ?? 0)) /
            100,
        0
      );

      const totalDiscount = updatedItems.reduce(
        (acc, item: CourseDto) =>
          acc + (item.price * (item.discountPercentage || 0)) / 100,
        0
      );

      return {
        ...state,
        items: updatedItems,
        total: totalPrice,
        tax: totalTax,
        discount: totalDiscount,
        paidTotal: totalPrice + totalTax - totalDiscount,
      };
    }

    case "CLEAR_CART":
      return {
        ...state,
        items: [],
        total: 0,
        tax: 0,
        discount: 0,
        paidTotal: 0,
      };

    default:
      return state;
  }
}
