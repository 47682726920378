import { get, getMetricsForTeacher } from "@/actions/mertrics.action";
import { getAll } from "@/actions/orders.actions";
import { getAll as getAllCourses } from "@/actions/courses.actions";
import Chart from "@/components/auth/admin/dashboard/Chart";
import LastComments from "@/components/auth/admin/dashboard/LastComments";
import OrdersChart from "@/components/auth/admin/dashboard/OrdersChart";
import ReportCard from "@/components/auth/admin/dashboard/ReportCard";
import TeacherOrdersChart from "@/components/auth/admin/dashboard/TeacherOrdersChart";
import { UserContext, useUserContext } from "@/context/UserContext";
import { CourseDto } from "@/data/dtos/course.dto";
import { OrderData } from "@/data/dtos/order.dto";
import { UserData } from "@/data/dtos/user.dto";
import { OrderStatus } from "@/data/enums/order-status.enum";
import {
  faBox,
  faDollar,
  faEye,
  faMessage,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

export default function TeacherDashboard() {
  const dispatch = useDispatch();
  const user = useUserContext();
  const { metrics, status } = useSelector((state: any) => state.metrics);
  const { orders, listStatus } = useSelector((state: any) => state.order);
  const [loading, setLoading] = useState(true);

  // Fetch orders if listStatus is idle
  useEffect(() => {
    let isMounted = true;
    const fetchOrders = async () => {
      if (isMounted) {
        dispatch(getAll() as any);
      }
    };
    fetchOrders();
    setLoading(false);
    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    if (status === "idle") {
      if (user?.role === "teacher") {
        dispatch(getMetricsForTeacher(user._id!) as any);
      } else if (user?.role === "admin") {
        dispatch(get() as any);
      }
    }
  }, [status, dispatch]);

  // Chart data
  const data = metrics?.weeklyWageList;

  const rows = metrics?.monthlySalesData?.map((order: any, index) => ({
    id: index,
    no: index + 1,
    name: order.name,
    month: order.month,
    sales: order.sales,
    revenue: order.revenue,
    tax: order.tax,
    total: order,
  }));

  if (loading) {
    return <Skeleton count={5} height={50} />;
  }

  return (
    <div className="container mx-auto rounded bg-white flex flex-col p-4">
      <h1 className="justify-center w-full text-center text-xl font-semibold">
        Hoş Geldiniz, {user?.name ?? "Guest"}!
      </h1>
      {/* Metrics Grid */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 my-4">
        {metrics?.metrics?.map((metric) => (
          <ReportCard metric={metric} key={metric.id} />
        ))}
      </div>
      {/* Chart Section */}
      <Chart data={data} />
      {/* Orders Section */}
      <TeacherOrdersChart recentOrders={rows} />
    </div>
  );
}
