import { setError } from '@/actions/message';
import { logger } from '@/lib/default-logger';
import store from '@/redux/store';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

class BaseService {
  private readonly baseURL: string;
  private readonly instance: AxiosInstance;
  constructor(baseURL: string) {
    logger.log('baseURL', baseURL);
    this.baseURL = baseURL;

    this.instance = axios.create({
      baseURL: this.baseURL,
    });

    this.instance.interceptors.request.use(
      (config) => {
        const persistRoot = localStorage.getItem('persist:root'); // 'auth' anahtarını al
        let token: string | null = null;
        if (persistRoot) {
          try {
            const parsedRoot = JSON.parse(persistRoot); // JSON ayrıştırması yap
            const parsedAuthData = JSON.parse(parsedRoot.auth);
            logger.log('auth data:', parsedAuthData);
            token = parsedAuthData?.token; // Token alanını kontrol et
          } catch (error) {
            logger.error('Auth verisi ayrıştırılamadı:', error);
          }
        }

        logger.log('Token:', token);

        if (token) {
          config.headers.Authorization = `Bearer ${token}`; // Header'a ekle
        }

        logger.log('Config Headers:', config.headers);
        return config;
      },
      (error) => Promise.reject(new Error(error))
    );
  }

  async get(endpoint: string, config?: AxiosRequestConfig) {
    try {
      console.log('GET Request:', this.baseURL + endpoint, config);
      return await this.instance.get(endpoint, config);
    } catch (error: any) {
      store.dispatch(setError(error.response.data.message) as any);
    }
  }

  async post(endpoint: string, data: any, config?: AxiosRequestConfig) {
    try {
      console.log('POST Request:', this.baseURL + endpoint, config);
      return await this.instance.post(this.baseURL + endpoint, data, config);
    } catch (error: any) {
      console.log('POST Error:', error);
      store.dispatch(setError(error.response.data.message) as any);
    }
  }

  async patch(endpoint: string, data: any, config?: AxiosRequestConfig) {
    try {
      return await this.instance.patch(this.baseURL + endpoint, data, config);
    } catch (error: any) {
      store.dispatch(setError(error.response.data.message) as any);
    }
  }

  async put(endpoint: string, data: any, config?: AxiosRequestConfig) {
    try {
      logger.debug('PUT Request:', this.baseURL + endpoint, data, config);
      return await this.instance.put(this.baseURL + endpoint, data, config);
    } catch (error: any) {
      store.dispatch(setError(error.response.data.message) as any);
    }
  }

  async delete(endpoint: string, config?: AxiosRequestConfig) {
    try {
      return await this.instance.delete(this.baseURL + endpoint, config);
    } catch (error: any) {
      store.dispatch(setError(error.response.data.message) as any);
    }
  }
}

export const apiClient = new BaseService(
  process.env.REACT_APP_API_BASE_URL ?? ''
);
