import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from "@mui/material";
import { QuizzDto } from "@/data/dtos/quizz.dto";

interface QuizComponentProps {
  quiz: QuizzDto;
  onComplete?: (successRate: number) => void;
  isCompleted?: boolean;
  successRate?: number;
}

const QuizComponent: React.FC<QuizComponentProps> = ({
  quiz,
  onComplete,
  isCompleted = false,
  successRate = 0,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState<number[]>([]);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [showResults, setShowResults] = useState(isCompleted);

  useEffect(() => {
    isCompleted ? setShowResults(true) : resetQuizState();
  }, [quiz, isCompleted]);

  const resetQuizState = useCallback(() => {
    setCurrentQuestionIndex(0);
    setUserAnswers([]);
    setSelectedOption(null);
    setShowResults(false);
  }, []);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(Number(event.target.value));
  };

  const handleConfirmAnswer = () => {
    if (selectedOption === null) {
      alert("Lütfen bir seçenek seçin.");
      return;
    }

    const updatedAnswers = [...userAnswers, selectedOption];
    setUserAnswers(updatedAnswers);

    if (currentQuestionIndex + 1 < quiz.questions.length) {
      setCurrentQuestionIndex((prev) => prev + 1);
      setSelectedOption(null);
    } else {
      setShowResults(true);
      handleQuizCompletion(updatedAnswers);
    }
  };

  const handleQuizCompletion = (answers: number[]) => {
    const { correctCount, total } = calculateResults(answers);
    const computedSuccessRate = (correctCount / total) * 100;

    onComplete?.(computedSuccessRate);
  };

  const calculateResults = (answers: number[]) => {
    const correctCount = quiz.questions.reduce(
      (count, question, index) =>
        answers[index] === question.correctAnswer ? count + 1 : count,
      0
    );

    return { correctCount, total: quiz.questions.length };
  };

  const renderResults = () => {
    const { correctCount, total } = isCompleted
      ? {
          correctCount: Math.round((successRate / 100) * quiz.questions.length),
          total: quiz.questions.length,
        }
      : calculateResults(userAnswers);

    return (
      <Box p={3} border={1} borderRadius={2} borderColor="grey.300">
        <Typography variant="h5">Sonuçlar</Typography>
        <Typography variant="body1">
          Doğru Sayısı: {correctCount} / {total}
        </Typography>
        <Typography variant="body1">Başarı Oranı: %{successRate}</Typography>

        {quiz.questions.map((question, index) => (
          <Box
            key={index}
            mt={2}
            p={2}
            border={1}
            borderRadius={1}
            borderColor="grey.300"
          >
            <Typography variant="subtitle1">
              {index + 1}. {question.question}
            </Typography>
            <Typography
              variant="body2"
              color={
                userAnswers[index] === question.correctAnswer ? "green" : "red"
              }
            >
              Sizin cevabınız: {question.options[userAnswers[index]]}
            </Typography>
            <Typography variant="body2" color="green">
              Doğru cevap: {question.options[question.correctAnswer]}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  if (showResults) return renderResults();

  return (
    <Box p={3} border={1} borderRadius={2} borderColor="grey.300">
      <Typography variant="h6">
        {currentQuestionIndex + 1}.{" "}
        {quiz.questions[currentQuestionIndex].question}
      </Typography>
      <RadioGroup
        value={selectedOption !== null ? selectedOption.toString() : ""}
        onChange={handleOptionChange}
      >
        {quiz.questions[currentQuestionIndex].options.map((option, idx) => (
          <FormControlLabel
            key={idx}
            value={idx.toString()}
            control={<Radio />}
            label={`${String.fromCharCode(65 + idx)}. ${option}`}
          />
        ))}
      </RadioGroup>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 3 }}
        onClick={handleConfirmAnswer}
      >
        Onayla
      </Button>
    </Box>
  );
};

export default QuizComponent;
