import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function CourseSections({ sections, setCurrentResource }: any) {
  // Ensure sections is an array
  const isValidArray = Array.isArray(sections) && sections.length > 0;
  const [currentSections, setCurrentSections] = useState([]);

  useEffect(() => {
    setCurrentSections(sections);
  }, [sections]);

  return (
    <section className="mt-6">
      <h3 className="text-2xl font-bold mb-4 text-gray-800">Bölümler</h3>
      {isValidArray && Array.isArray(currentSections) ? (
        currentSections?.map((section: any) => (
          <Accordion
            key={section._id}
            className="rounded-lg border shadow-md bg-gray-50 hover:shadow-lg transition"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className="px-4 py-2 bg-indigo-100 hover:bg-indigo-200 rounded-t-lg"
            >
              <span className="font-semibold text-gray-700">
                {section.title}
              </span>
            </AccordionSummary>
            <AccordionDetails className="px-4 py-2 space-y-2">
              {Array.isArray(section.resources) &&
              section.resources.length > 0 ? (
                section.resources.map((resource: any) => (
                  <div
                    key={resource._id}
                    className="flex justify-between items-center p-2 border rounded-lg bg-white hover:bg-gray-100 transition"
                  >
                    <button
                      onClick={() => setCurrentResource(resource)}
                      disabled={resource?.visibility === "private"}
                      className={`text-left ${
                        resource?.visibility === "private"
                          ? "text-gray-400 cursor-not-allowed"
                          : "text-indigo-600 hover:text-indigo-800"
                      }`}
                    >
                      {resource.title}
                    </button>
                    {resource?.visibility === "private" && (
                      <span className="text-sm text-red-500">Özel</span>
                    )}
                  </div>
                ))
              ) : (
                <div className="p-2 text-gray-500 text-sm italic">
                  Kaynak yok
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <p className="text-gray-500 italic">Bölüm yok</p>
      )}
    </section>
  );
}
