import { addToCart } from "@/actions/cart.actions";
import { addToWhistList } from "@/actions/whisList.action";
import { CourseDto } from "@/data/dtos/course.dto";
import { formatMoney } from "@/utils";
import React, { useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { UserContext } from "@/context/UserContext";

export default function CourseMain({
  course,
  sections,
}: {
  readonly course: CourseDto;
  readonly sections: any;
}) {
  const dispatch = useDispatch();
  const mainImageRef = React.useRef<HTMLImageElement>(null);
  const [loading, setLoading] = useState(false); // State to track loading
  const [imageError, setImageError] = useState(false); // State for fallback image

  const averageCourseRating = () => {
    let sum = 0;
    let count = 0;
    if (course?.reviews?.length! > 0) {
      course?.reviews?.forEach((review) => {
        sum += review.rating;
        count += 1;
      });
    }
    return count > 0 ? parseFloat((sum / count).toFixed(1)) : 0;
  };

  const changeImage = (e: any) => {
    if (mainImageRef.current === null) {
      console.error("mainImageRef is null");
      return;
    }

    try {
      setLoading(false);
      const backgroundImage = e.target.style.backgroundImage;
      console.log(backgroundImage);
      const url = backgroundImage.slice(5, -2);
      console.log(url);
      mainImageRef.current.src = url;
      console.log(mainImageRef.current.src);
      setImageError(false); // Reset error state on image change
    } catch (error) {
      console.error("Error occurred while changing image", error);
    }
  };

  const handleAddToCart = () => {
    dispatch(addToCart(course) as any);
  };

  const handleAddToWhistList = () => {
    dispatch(addToWhistList(course) as any);
  };

  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  const { user } = userContext;

  const isOwnedByUser = useMemo(
    () =>
      user?.userCourseProgress?.some(
        (crs: any) => crs.course._id === course?._id
      ),
    [user, course]
  );

  const translateLevel = (level: string) => {
    switch (level) {
      case "Beginner":
        return "Başlangıç";
      case "Intermediate":
        return "Orta Seviye";
      case "Advanced":
        return "İleri Düzey";
      default:
        return "Bilinmiyor";
    }
  };

  return (
    <div className="container mx-auto p-6 bg-gray-50 rounded-lg shadow-lg">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Product Images */}
        <div className="w-full">
          {loading && (
            <div ref={mainImageRef}>
              <Skeleton className="w-full h-72 rounded-lg" />
            </div>
          )}{" "}
          {/* Skeleton */}
          {!loading && imageError && (
            <img
              ref={mainImageRef}
              src="/images/fallback-image.png" // Replace with your fallback image path
              alt="Fallback"
              className="w-full h-72 rounded-lg object-cover shadow-md"
            />
          )}
          {!loading && !imageError && (
            <img
              ref={mainImageRef}
              src={course?.images![0]?.url}
              alt={course?.title || "Course Image"}
              className="w-full h-auto rounded-lg shadow-md mb-4 object-cover"
              id="mainImage"
              onLoad={() => setLoading(false)} // Remove skeleton once loaded
              onError={() => setImageError(true)} // Show fallback on error
            />
          )}
          <div className="flex gap-3 justify-center overflow-x-auto">
            {course?.images?.map((image: any) => (
              <button
                key={image._id}
                style={{ backgroundImage: `url("${image.url}")` }}
                className="w-16 h-16 bg-cover rounded-md border border-gray-300 hover:border-indigo-600 transition"
                onClick={changeImage}
              />
            ))}
          </div>
        </div>

        {/* Product Details */}
        <div className="w-full justify-items-center md:justify-items-start">
          <h2 className="text-3xl font-extrabold text-gray-800">
            {course?.title}
          </h2>
          <div className="space-y-2">
            {course?.discountPercentage > 0 ? (
              <div className="flex items-center gap-4">
                <span className="text-2xl font-bold text-indigo-600">
                  {formatMoney(
                    course?.price -
                      course?.price * (course?.discountPercentage / 100)
                  )}
                </span>
                <span className="text-gray-500 line-through">
                  {formatMoney(course?.price)}
                </span>
              </div>
            ) : (
              <span className="text-2xl font-bold text-indigo-600">
                {formatMoney(course?.price)}
              </span>
            )}
          </div>
          <div className="flex items-center gap-2 text-yellow-500">
            {[...Array(5)].map((_, i) => (
              <svg
                key={i}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill={
                  i < Math.floor(averageCourseRating()) ? "#fbbf24" : "#e5e7eb"
                }
                className="w-5 h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                  clipRule="evenodd"
                />
              </svg>
            ))}
            <span className="ml-2 text-gray-600 text-sm">
              {averageCourseRating()} ({course?.reviews?.length} inceleme)
            </span>
          </div>
          {isOwnedByUser || user?.role === "student" ? (
            <p className="text-green-600 text-sm font-medium w-full p-4 border-green-500 border rounded hover:text-white hover:bg-green-500 cursor-pointer">
              Bu Atölyeye Sahipsin
            </p>
          ) : (
            <div className="flex gap-4">
              <button
                onClick={handleAddToCart}
                className="px-6 py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition"
              >
                Sepete Ekle
              </button>
              <button
                onClick={handleAddToWhistList}
                className="px-6 py-3 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition"
              >
                İstek Listesine Ekle
              </button>
            </div>
          )}

          <div>
            <h3 className="text-lg font-semibold mb-2 text-gray-800">
              Atölye Hakkında
            </h3>
            <ul className="space-y-1 text-gray-700 max-w-xs">
              <li className="grid grid-cols-3">
                <span className="font-semibold">Bölümler</span>
                <span className="font-semibold">:</span>
                <span>{sections?.length || 0}</span>
              </li>
              <li className="grid grid-cols-3">
                <span className="font-semibold">Dokümanlar</span>
                <span className="font-semibold">:</span>
                <span>{course?.documents?.length ?? 0}</span>
              </li>
              <li className="grid grid-cols-3">
                <span className="font-semibold">Seviye</span>
                <span className="font-semibold">:</span>
                <span>{translateLevel(course?.level)}</span>
              </li>
              <li className="grid grid-cols-3">
                <span className="font-semibold">Dili</span>
                <span className="font-semibold">:</span>
                <span>{course?.languages?.join(", ")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Description */}
      <div className="mt-8 border-t pt-4">
        <p className="text-gray-700">{course?.description}</p>
      </div>
    </div>
  );
}
