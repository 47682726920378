import { ImageDto } from "@/data/dtos/image.dto";
import React, { useState, useRef } from "react";

interface ImagePickerModalProps {
  isOpen: boolean;
  images: ImageDto[];
  onSelect: (image: ImageDto) => void;
  onClose: () => void;
}

const ImagePickerModal: React.FC<ImagePickerModalProps> = ({
  isOpen,
  images,
  onSelect,
  onClose,
}) => {
  const [tab, setTab] = useState<"library" | "upload">("library");
  const [selectedImage, setSelectedImage] = useState<ImageDto | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [page, setPage] = useState(0);
  const imagesPerPage = 20; // Increased image count per page

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const fileURL = URL.createObjectURL(files[0]);
      onSelect({
        _id: "",
        url: fileURL,
        altText: "Uploaded Image",
        isOnUse: false,
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      onClose();
    }
  };

  if (!isOpen) return null;

  // Paginate images
  const totalPages = Math.ceil(images.length / imagesPerPage);
  const paginatedImages = images.slice(
    page * imagesPerPage,
    (page + 1) * imagesPerPage
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 container mx-auto shadow-lg flex">
        {/* Left Side - Image Selection & Upload */}
        <div className="w-2/3 border-r pr-4">
          {/* Header */}
          <div className="flex justify-between items-center border-b pb-3">
            <h1 className="text-xl font-semibold">Ortam Ekle</h1>
            <button
              onClick={onClose}
              className="text-gray-600 hover:text-black text-2xl"
            >
              &times;
            </button>
          </div>

          {/* Tabs */}
          <div className="flex space-x-4 my-4 border-b pb-2">
            <button
              className={`px-4 py-2 ${
                tab === "library"
                  ? "border-b-2 border-blue-600 font-semibold"
                  : "text-gray-500"
              }`}
              onClick={() => setTab("library")}
            >
              Ortam Kitaplığı
            </button>
            <button
              className={`px-4 py-2 ${
                tab === "upload"
                  ? "border-b-2 border-blue-600 font-semibold"
                  : "text-gray-500"
              }`}
              onClick={() => setTab("upload")}
            >
              Dosya Yükle
            </button>
          </div>

          {/* Content */}
          {tab === "library" ? (
            <div>
              {/* Scrollable Image Grid */}
              <div className="max-h-[400px] overflow-y-auto grid grid-cols-5 gap-3 p-2">
                {paginatedImages.map((image, index) => (
                  <div
                    key={image._id}
                    className={`border p-2 rounded-md cursor-pointer hover:border-blue-600 relative ${
                      selectedImage?._id === image._id
                        ? "border-2 border-blue-600"
                        : ""
                    }`}
                    onClick={() => setSelectedImage(image)}
                  >
                    <img
                      src={image.url}
                      alt={image.altText}
                      className="w-full h-24 object-cover rounded-md"
                    />
                    {selectedImage?._id === image._id && (
                      <div className="absolute top-0 right-0 bg-blue-600 text-white px-2 py-1 text-xs rounded-bl">
                        Seçildi
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* Pagination Controls */}
              <div className="flex justify-between items-center mt-4">
                <button
                  className={`px-4 py-2 ${
                    page > 0
                      ? "bg-gray-300 hover:bg-gray-400"
                      : "bg-gray-200 cursor-not-allowed"
                  }`}
                  onClick={() => setPage(page - 1)}
                  disabled={page === 0}
                >
                  ⬅ Önceki
                </button>
                <span className="text-gray-600">
                  Sayfa {page + 1} / {totalPages}
                </span>
                <button
                  className={`px-4 py-2 ${
                    page < totalPages - 1
                      ? "bg-gray-300 hover:bg-gray-400"
                      : "bg-gray-200 cursor-not-allowed"
                  }`}
                  onClick={() => setPage(page + 1)}
                  disabled={page >= totalPages - 1}
                >
                  Sonraki ➡
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center p-6 border-dashed border-2 border-gray-300 rounded-md text-center">
              <p className="text-gray-500">Dosyaları buraya sürükleyin veya</p>
              <button
                onClick={() => fileInputRef.current?.click()}
                className="mt-3 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
              >
                Dosya Seçin
              </button>
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                onChange={handleFileUpload}
                accept="image/*"
              />
            </div>
          )}
        </div>

        {/* Right Side - Image Details */}
        {/* Right Side - Image Details (Editable Form) */}
        <div className="w-1/3 pl-4">
          {selectedImage ? (
            <div>
              <h2 className="text-lg font-semibold mb-2">Görsel Detayları</h2>

              {/* Image Preview */}
              <img
                src={selectedImage.url}
                alt={selectedImage.altText}
                className="w-full h-40 object-cover rounded-md mb-3"
              />

              {/* Editable Form */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onSelect(selectedImage);
                  onClose();
                }}
              >
                {/* Alt Text (Editable) */}
                <label className="block mt-3 mb-2 text-sm font-medium text-gray-700">
                  Alternatif Metin:
                </label>
                <input
                  type="text"
                  value={selectedImage.altText}
                  onChange={(e) =>
                    setSelectedImage({
                      ...selectedImage,
                      altText: e.target.value,
                    })
                  }
                  className="w-full px-3 py-2 border rounded-md text-sm text-gray-700"
                />

                {/* IsOnUse Toggle */}
                <label className="block mt-3 mb-2 text-sm font-medium text-gray-700">
                  Kullanımda mı?
                </label>
                <select
                  value={selectedImage.isOnUse ? "true" : "false"}
                  onChange={(e) =>
                    setSelectedImage({
                      ...selectedImage,
                      isOnUse: e.target.value === "true",
                    })
                  }
                  className="w-full px-3 py-2 border rounded-md text-sm text-gray-700"
                >
                  <option value="true">Evet</option>
                  <option value="false">Hayır</option>
                </select>

                {/* Created Date (Readonly) */}
                <label className="block mt-3 mb-2 text-sm font-medium text-gray-700">
                  Oluşturma Tarihi:
                </label>
                <input
                  type="text"
                  value={new Date(
                    selectedImage.createdAt!
                  ).toLocaleDateString()}
                  readOnly
                  className="w-full px-3 py-2 border rounded-md text-sm bg-gray-100 text-gray-500"
                />

                {/* Save & Select Button */}
                <button
                  type="submit"
                  className="mt-4 w-full bg-blue-600 text-white p-2 rounded-md hover:bg-blue-700"
                >
                  Güncelle ve Seç
                </button>
              </form>
            </div>
          ) : (
            <p className="text-gray-500 text-center">Görsel seçin...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImagePickerModal;
