import { ReviewDto } from "@/data/dtos/review.dto";
import { UserData } from "@/data/dtos/user.dto";
import React from "react";

export default function CommentCard({ comment }: { comment: ReviewDto }) {
  const user = comment.user as UserData;
  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img
            src={user.profileImage}
            alt="user avatar"
            className="w-12 h-12 rounded-full mr-4"
          />
          <div>
            <h4 className="text-lg font-semibold">{user.name}</h4>
            <p className="text-gray-600">
              {new Date(comment.date!).toLocaleDateString("tr-TR", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </p>
          </div>
        </div>
        <div>
          <p className="text-lg font-semibold">{comment.rating}/5</p>
        </div>
      </div>
      <p className="mt-4">{comment.comment}</p>
    </div>
  );
}
