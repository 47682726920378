import { getAll, remove } from "@/actions/category.actions";
import ImageColumn from "@/components/auth/admin/core/ImageColumn";
import Table from "@/components/core/Table";
import OptionsMenu from "@/components/core/TableOptionMenu";
import { formatMoney, url } from "@/utils";
import { Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function CategoriesList() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { categories, status } = useSelector((state: any) => state.categories);

  const handleDelete = async (id) => {
    dispatch(remove(id) as any);
  };

  useEffect(() => {
    if (status === "idle") {
      dispatch(getAll() as any);
    }
  });

  const pageSizeOptions = [25, 50, 100];

  const columns = [
    {
      field: "_id",
      headerName: "Kategori Id",
      width: 300,
    },
    {
      field: "name",
      headerName: "Kategori Adı",
      width: 200,
    },
    {
      field: "parent",
      headerName: "Ana Kategori",
      width: 200,
      valueGetter: (param: any) => param?.name ?? "N/A",
    },
    {
      field: "status",
      headerName: "Durum",
      width: 200,
    },
    {
      field: "image",
      headerName: "Görsel",
      width: 130,
      renderCell: (params) => {
        return <ImageColumn param={params.row} />;
      },
    },
    {
      field: "options",
      headerName: "Seçenekler",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation(); // Prevent row selection on click
          // You can manage the menu state in the parent or directly in the component
        };

        return (
          <Stack direction="row" spacing={1}>
            <OptionsMenu
              id={params.row._id}
              onEdit={() => {
                navigation(
                  url("Admin.Categories.Edit", { id: params.row._id })
                );
              }}
              onDelete={handleDelete}
              onOpenMenu={handleMenuOpen} // Pass the handler to OptionsMenu
            />
          </Stack>
        );
      },
    },
  ];

  if (categories?.length > 0) {
    return (
      <div className="w-full bg-white">
        <DataGrid
          rows={categories}
          columns={columns}
          pageSizeOptions={pageSizeOptions}
          checkboxSelection
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: pageSizeOptions[0] },
            },
          }}
          getRowId={(row) => row._id} // Benzersiz ID için _id kullan
        />
      </div>
    );
  } else {
    return <div>Kategori Yok</div>;
  }
}
