export enum CourseStatus {
  DRAFT = 'Draft',
  PENDING = 'Pending',
  PUBLISHED = 'Published',
  REJECTED = 'Rejected',
  ARCHIVED = 'Archived',
  DELETED = 'Deleted',
  UNPUBLISHED = 'Unpublished',
  SENDEDTOPUBLISHER = 'SentToPublisher',
}
