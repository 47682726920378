import imageService from "@/services/images.service";
import { setError, setMessage } from "./message";
import {
  CREATE_IMAGE_FAILED,
  CREATE_IMAGE_SUCCESS,
  FETCH_IMAGES,
  FETCH_IMAGES_FAILED,
  FETCH_IMAGES_SUCCESS,
  FILE_UPLOADING_STARTED,
  REMOVE_IMAGE_FAILED,
  REMOVE_IMAGE_SUCCESS,
  UPLOAD_FILE_FAILED,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
} from "./type";

export const create = (payload: any) => async (dispatch: any) => {
  dispatch({ type: "IMAGE_CREATE_REQUEST" });

  await imageService
    .create(payload)
    .then((response: any) => {
      // console.log(response.data.data);
      dispatch({
        type: CREATE_IMAGE_SUCCESS,
        payload: response.data.data,
      });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve();
    })
    .catch((error: any) => {
      console.log(error);
      const message = error.response.data;
      console.log(message);
      dispatch({ type: CREATE_IMAGE_FAILED });
      dispatch(setError(message));
      return Promise.reject(new Error(message));
    });
};

export const upload = (payload: any) => async (dispatch: any) => {
  dispatch({ type: UPLOAD_FILE_REQUEST });

  await imageService
    .upload(payload, (progressEvent: any) => {
      dispatch({
        type: FILE_UPLOADING_STARTED,
        payload: progressEvent,
      });
    })
    .then((response: any) => {
      // console.log(response.data.data);
      dispatch({
        type: UPLOAD_FILE_SUCCESS,
        payload: response.data,
      });

      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve(response.data);
    })
    .catch((error: any) => {
      console.log(error);
      const message = error.response.data;
      dispatch({ type: UPLOAD_FILE_FAILED, payload: message });
      dispatch(setError(message));
      return Promise.reject(new Error(message));
    });
};

export const getAll = () => async (dispatch: any) => {
  dispatch({ type: FETCH_IMAGES });

  await imageService
    .getAll()
    .then((res) => {
      dispatch({ type: FETCH_IMAGES_SUCCESS, payload: res?.data });
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      const message = err.response.data;
      console.log(message);
      dispatch({ type: FETCH_IMAGES_FAILED });
      dispatch(setError(message));
      return Promise.reject(new Error(message));
    });
};

export const get = (id: string) => async (dispatch: any) => {
  dispatch({ type: FETCH_IMAGES });
  await imageService
    .get(id)
    .then((res) => {
      dispatch({ type: FETCH_IMAGES_SUCCESS, payload: res?.data });
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      const message = err.response.data;
      console.log(message);
      dispatch({ type: FETCH_IMAGES_FAILED });
      dispatch(setError(message));
      return Promise.reject(new Error(message));
    });
};

export const update =
  (id: string, payload: ImageData) => async (dispatch: any) => {
    dispatch({ type: "IMAGE_UPDATE_REQUEST" });

    await imageService
      .update(id, payload)
      .catch((res) => {
        dispatch({ type: CREATE_IMAGE_SUCCESS, payload: res.data });
        dispatch(setMessage(res.data.message, 5000));
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err);
        const message = err.response.data;
        console.log(message);
        dispatch({ type: CREATE_IMAGE_FAILED });
        dispatch(setError(message));
        return Promise.reject(new Error(message));
      });
  };

export const remove = (id: string) => async (dispatch: any) => {
  dispatch({ type: "IMAGE_REMOVE_REQUEST" });

  await imageService
    .remove(id)
    .then((res) => {
      dispatch({ type: REMOVE_IMAGE_SUCCESS, payload: res?.data });
      dispatch(setMessage(res?.data.message, 5000));
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      const message = err;
      console.log(message);
      dispatch({ type: REMOVE_IMAGE_FAILED });
      dispatch(setError(message));
      return Promise.reject(new Error(message));
    });
};

export const clearState = () => (dispatch: any) => {
  dispatch({ type: "CLEAR_IMAGE_STATE" });
};
