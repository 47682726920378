import invoiceService from "@/services/invoice.service";
import { setError, setMessage } from "./message";

export const create = (payload: any) => async (dispatch: any) => {
  await invoiceService
    .create(payload)
    .then((response: any) => {
      dispatch({ type: "CREATE_INVOICE_SUCCESS", payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: "CREATE_INVOICE_FAIL" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const getAll = () => async (dispatch: any) => {
  await invoiceService
    .getAll()
    .then((response: any) => {
      dispatch({ type: "FETCH_INVOICES_SUCCESS", payload: response.data.data });
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: "FETCH_INVOICES_FAILED" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const get = (id: string) => async (dispatch: any) => {
  await invoiceService
    .get(id)
    .then((response: any) => {
      dispatch({ type: "FETCH_INVOICE_SUCCESS", payload: response.data });
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: "FETCH_INVOICE_FAILED" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const remove = (id: string) => async (dispatch: any) => {
  await invoiceService
    .delete(id)
    .then((response: any) => {
      dispatch({ type: "DELETE_INVOICE_SUCCESS", payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: "DELETE_INVOICE_FAILED" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};
