import AuthLayout from "@/app/views/auth/layouts/AuthLayout";
import Contact from "@/app/views/home/Contact";
import Login from "@/app/views/auth/pages/Login";
import Register from "@/app/views/auth/pages/Register";
import AdminDashboard from "@/app/views/auth/admin/Dashboard";
import PrivateRoute from "./components/auth/PrivateRoute";
import AdminLayout from "@/app/views/auth/admin/AdminLayout";
import ForgotPassword from "@/app/views/auth/pages/ForgotPassword";
import ResetPassword from "@/app/views/auth/pages/ResetPassword";
import Cart from "@/app/views/cart/Cart";
import Courses from "@/app/views/courses/Courses";
import React from "react";
import PaymentPage from "@/app/views/cart/PaymentPage";
import PaymentSuccess from "@/app/views/cart/PaymentSuccess";
import PaymentFailed from "@/app/views/cart/PaymentFailed";
import CreateCourse from "@/app/views/auth/admin/courses/CreateCourse";
import CourseList from "@/app/views/auth/admin/courses/CourseList";
import EditCourse from "@/app/views/auth/admin/courses/EditCourse";
import Course from "@/app/views/courses/Course";
import { Navigate } from "react-router-dom";
import AllCourses from "@/app/views/auth/admin/courses/AllCourses";
import CourseDetail from "@/app/views/courses/CourseDetail";
import CourseLayout from "@/app/views/courses/CourseLayout";
import GeneralSettings from "@/app/views/auth/admin/settings/GeneralSettings";
import StudentLayout from "@/app/views/auth/layouts/StudentLayout";
import MyCourses from "@/app/views/auth/student/MyCourses";
import AdminInnerPanelLayout from "@/app/views/auth/layouts/AdminInnerPanelLayout";
import Home from "@/app/views/home/Home";
import UserList from "@/app/views/auth/admin/users/UserList";
import CreateUser from "@/app/views/auth/admin/users/CreateUser";
import CategoriesList from "@/app/views/auth/admin/categories/CategoriesList";
import CreateCategory from "@/app/views/auth/admin/categories/CreateCategory";
import UpdateCategory from "@/app/views/auth/admin/categories/UpdateCategory";
import UpdateUser from "@/app/views/auth/admin/users/UpdateUser";
import OrderList from "@/app/views/auth/admin/orders/OrderList";
import CreateOrder from "@/app/views/auth/admin/orders/CreateOrder";
import UpdateOrder from "@/app/views/auth/admin/orders/UpdateOrder";
import Profile from "@/app/views/auth/pages/Profile";
import Forbidden from "@/app/views/errors/Forbidden";
import NotFound from "@/app/views/errors/NotFound";
import MainLayout from "@/app/views/layouts/MainLayout";
import EditProfile from "./app/views/auth/pages/EditProfile";
import Addresses from "./app/views/auth/pages/Addresses";
import CreateSlider from "./app/views/auth/admin/sliders/CreateSlider";
import SliderList from "./app/views/auth/admin/sliders/SliderList";
import UpdateSlider from "./app/views/auth/admin/sliders/UpdateSldier";
import AboutOnlineEducation from "./app/views/home/abouts/AboutOnlineEducation";
import AboutFictionalEvent from "./app/views/home/abouts/AboutFictionalEvent";
import FictionalEvent from "./app/views/fictionalEvents/FictionalEvent";
import AboutLayout from "./app/views/layouts/AboutLayout";
import Security from "./app/views/auth/pages/Security";
import ContentList from "./app/views/auth/admin/contents/ContentList";
import Images from "./app/views/auth/admin/contents/Images";
import Videos from "./app/views/auth/admin/contents/Videos";
import Docs from "./app/views/auth/admin/contents/Docs";
import ReturnPolicy from "./app/views/home/ReturnPolicy";
import RefoundPolicy from "./app/views/home/RefoundPolicy";
import PolicyLayout from "./app/views/layouts/PolicyLayout";
import WhisList from "./components/cart/WhisList";
import EditCv from "./app/views/auth/pages/EditCv";
import TeacherList from "./app/views/auth/admin/users/TeacherList";
import View from "./app/views/auth/admin/users/View";
import AgreementList from "./app/views/auth/admin/agreements/AgreementList";
import CreateAgreement from "./app/views/auth/admin/agreements/CreateAgreement";
import EditAgreement from "./app/views/auth/admin/agreements/EditAgreement";
import PersonalData from "./app/views/home/PersonalData";
import PendingCourses from "./app/views/auth/admin/courses/PendingCourses";
import path from "path";
import SendPayment from "./app/views/cart/SendPayment";
import Courseview from "./app/views/auth/admin/courses/Courseview";
import InvoiceList from "./app/views/auth/admin/invoices/InvoiceList";
import CreateInvoice from "./app/views/auth/admin/invoices/CreateInvoice";
import EditInvoice from "./app/views/auth/admin/invoices/EditInvoice";

interface Route {
  path: string;
  name: string;
  title?: string;
  element: React.ReactNode;
  auth?: boolean;
  role?: string;
  children?: Route[];
}

const routes = [
  {
    path: "/",
    name: "Home",
    title: "Ana Sayfa",
    element: <MainLayout />,
    children: [
      {
        index: true,
        path: "/",
        name: "Home",
        title: "Ana Sayfa",
        element: <Home />,
      },
      {
        name: "About",
        path: "/about",
        title: "Hakkımızda",
        element: <AboutLayout />,
        children: [
          {
            path: "online-workshop",
            name: "Online-Workshop",
            title: "Online Atölye Platformumuz",
            element: <AboutOnlineEducation />,
          },
          {
            path: "fictional-workshop",
            name: "Fictional-Workshop",
            title: "Kurgusal Etkinlik Platformumuz",
            element: <AboutFictionalEvent />,
          },
        ],
      },
      {
        path: "/workshops",
        name: "Workshops",
        title: "Online Atölyeler",
        element: <Courses />,
      },
      {
        path: "/fictional-events",
        name: "Online",
        title: "Kurgusal Etkinlik",
        element: <FictionalEvent />,
      },
      {
        name: "Contact",
        path: "/contact",
        title: "İletişim",
        element: <Contact />,
      },
    ],
  },
  {
    name: "Policies",
    path: "/policies",
    title: "Politikalar",
    element: <PolicyLayout />,
    children: [
      {
        name: "Return-Policy",
        path: "return-policy",
        title: "İade Politikası",
        element: <ReturnPolicy />,
      },
      {
        name: "Refund-Policy",
        path: "refound-policy",
        title: "Geri Ödeme Politikası",
        element: <RefoundPolicy />,
      },
      {
        name: "Personal-Data-Protection",
        path: "personal-data-protection",
        title: "Kişisel Verilerin Korunması",
        element: <PersonalData />,
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    element: <AuthLayout />,
    children: [
      {
        index: true,
        path: "login",
        name: "Login",
        element: <Login />,
      },
      {
        path: "register",
        name: "Register",
        element: <Register />,
      },
      {
        path: "forgot-password",
        name: "Forgot-Password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        name: "Reset Password",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "/profile",
    name: "Profile",
    element: <StudentLayout />,
    auth: true,
    children: [
      {
        index: true,
        path: "/profile",
        name: "Profile",
        element: <Profile />,
      },
      {
        path: "addresses",
        name: "Addresses",
        element: <Addresses />,
      },
      {
        path: "security",
        name: "Security",
        element: <Security />,
      },
      {
        path: "edit/profile/:id",
        name: "Edit-Profile",
        element: <EditProfile />,
      },
      {
        path: "my-courses",
        name: "My-Courses",
        element: <MyCourses />,
      },
      {
        path: "course/:id",
        name: "Course",
        element: <Course />,
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    element: <AdminLayout />,
    role: "teacher",
    children: [
      {
        index: true,
        path: "dashboard",
        name: "Dashboard",
        element: <AdminDashboard />,
      },
      {
        path: "courses",
        name: "Courses",
        element: <AdminInnerPanelLayout />,
        role: "teacher",
        children: [
          {
            index: true,
            path: "",
            name: "Courses",
            element: <CourseList />,
          },
          {
            path: "list",
            name: "Course-List",
            role: "admin",
            element: <AllCourses />,
          },
          {
            path: "pending-courses",
            name: "Pending-Courses",
            role: "admin",
            element: <PendingCourses />,
          },
          {
            path: "create",
            name: "Create",
            element: <CreateCourse />,
          },
          {
            path: "edit/:id",
            name: "Edit",
            element: <EditCourse />,
          },
          {
            path: "view/:id",
            name: "View",
            element: <Courseview />,
          },
        ],
      },
      {
        path: "users",
        name: "Users",
        element: <AdminInnerPanelLayout />,
        role: "admin",
        children: [
          {
            index: true,
            path: "",
            name: "Users",
            element: <UserList />,
          },
          {
            path: "teachers",
            name: "Teachers",
            element: <TeacherList />,
          },
          {
            path: "create",
            name: "Create",
            element: <CreateUser />,
          },
          {
            path: "edit/:id",
            name: "Edit",
            element: <UpdateUser />,
          },
          {
            path: "view/:id",
            name: "View",
            element: <View />,
          },
        ],
      },
      {
        path: "categories",
        name: "Categories",
        element: <AdminInnerPanelLayout />,
        role: "admin",
        children: [
          {
            index: true,
            path: "",
            name: "Categories",
            element: <CategoriesList />,
          },
          {
            path: "create",
            name: "Create",
            element: <CreateCategory />,
          },
          {
            path: "edit/:id",
            name: "Edit",
            element: <UpdateCategory />,
          },
        ],
      },
      {
        path: "orders",
        name: "Orders",
        element: <AdminInnerPanelLayout />,
        role: "admin",
        children: [
          {
            index: true,
            path: "",
            name: "Orders",
            element: <OrderList />,
          },
          {
            path: "create",
            name: "Create",
            element: <CreateOrder />,
          },
          {
            path: "edit/:id",
            name: "Edit",
            element: <UpdateOrder />,
          },
        ],
      },
      {
        path: "invoices",
        name: "Invoices",
        element: <AdminInnerPanelLayout />,
        role: "admin",
        children: [
          {
            index: true,
            path: "",
            name: "Invoices",
            element: <InvoiceList />,
          },
          {
            path: "create/:id",
            name: "Create",
            element: <CreateInvoice />,
          },
          {
            path: "edit/:id",
            name: "Edit",
            element: <EditInvoice />,
          },
        ],
      },
      {
        path: "site-settings",
        name: "Site-Settings",
        element: <AdminInnerPanelLayout />,
        role: "admin",
        children: [
          {
            index: true,
            path: "site-settings/general-settings",
            name: "General-Settings",
            element: <GeneralSettings />,
          },
        ],
      },
      {
        path: "sliders",
        name: "Sliders",
        element: <AdminInnerPanelLayout />,
        role: "admin",
        children: [
          {
            index: true,
            path: "",
            name: "Sliders",
            element: <SliderList />,
          },
          {
            path: "create",
            name: "Create",
            element: <CreateSlider />,
          },
          {
            path: "edit/:id",
            name: "Edit",
            element: <UpdateSlider />,
          },
        ],
      },
      {
        path: "contents",
        name: "Contents",
        element: <AdminInnerPanelLayout />,
        role: "admin",
        children: [
          {
            index: true,
            path: "",
            name: "Contents",
            element: <ContentList />,
          },
          {
            path: "images",
            name: "Images",
            element: <Images />,
          },
          {
            path: "videos",
            name: "Videos",
            element: <Videos />,
          },
          {
            path: "docs",
            name: "Docs",
            element: <Docs />,
          },
        ],
      },
      {
        path: "agreements",
        name: "Agreements",
        element: <AdminInnerPanelLayout />,
        role: "admin",
        children: [
          {
            index: true,
            path: "",
            name: "Agreements",
            element: <AgreementList />,
          },
          {
            path: "create",
            name: "Create",
            element: <CreateAgreement />,
          },
          {
            path: "edit/:id",
            name: "Edit",
            element: <EditAgreement />,
          },
        ],
      },
      {
        path: "cv/:id",
        name: "Cv",
        element: <EditCv />,
      },
    ],
  },
  {
    path: "/cart",
    name: "Cart",
    element: <MainLayout />,
    auth: true,
    children: [
      {
        index: true,
        path: "/cart",
        name: "Cart",
        element: <Cart />,
      },
      {
        path: "checkout",
        name: "Checkout",
        element: <PaymentPage />,
      },
      {
        path: "payment-success",
        name: "Payment-Success",
        element: <PaymentSuccess />,
      },
      {
        path: "payment-failed",
        name: "Payment-Failed",
        element: <PaymentFailed />,
      },
      {
        path: "send-payment",
        name: "Send-Payment",
        element: <SendPayment />,
      },
    ],
  },
  {
    path: "/whist-list",
    name: "Whist-List",
    element: <MainLayout />,
    auth: false,
    children: [
      {
        index: true,
        path: "/whist-list",
        name: "Whist-List",
        element: <WhisList />,
      },
    ],
  },
  {
    path: "/courses",
    name: "Courses",
    element: <CourseLayout />,
    children: [
      {
        index: true,
        path: "/courses",
        name: "Courses",
        element: <Courses />,
      },
      {
        path: "course/:slug",
        name: "Course-By-Slug",
        element: <CourseDetail />,
      },
    ],
  },
  {
    path: "*",
    name: "404",
    element: <NotFound />,
  },
  {
    path: "/403",
    name: "403",
    element: <Forbidden />,
  },
];

const wrapRouteWithRole = (element: React.ReactNode, role?: string) => {
  if (!role) return element;
  switch (role) {
    case "admin":
      return <PrivateRoute role="admin">{element}</PrivateRoute>;
    case "teacher":
      return <PrivateRoute role="teacher">{element}</PrivateRoute>;
    case "student":
      return <PrivateRoute role="student">{element}</PrivateRoute>;
    // Ek roller gerektiğinde buraya eklenebilir
    default:
      return <Navigate to="/403" />;
  }
};

const authMap = (routes: Route[]) =>
  routes.map((route) => {
    const { role, children, element } = route;

    route.element = wrapRouteWithRole(element, role);

    if (children && children.length > 0) {
      route.children = authMap(children);
    }

    return route;
  });

export default authMap(routes);
