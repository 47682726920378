import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import rootReducer from "../reducers";
import logger from "redux-logger";

// `localStorage`'dan `rememberMe` durumunu kontrol edin
const getRememberMe = () => {
  try {
    if (localStorage.getItem("rememberMe") === null) {
      localStorage.setItem("rememberMe", "true");
    }
    const rememberMe = localStorage.getItem("rememberMe");
    return rememberMe === "true"; // String olarak saklandığı için "true" ile karşılaştırıyoruz
  } catch (error) {
    console.error("Error fetching rememberMe state:", error);
    return false; // Hata durumunda false olarak döner
  }
};

console.log(getRememberMe());

// Persist config'i dinamik olarak oluşturun
const createPersistConfig = (rememberMe: boolean) => ({
  key: "root",
  storage,
  whitelist: ["counter", "cart", "auth", "whisList"], // rememberMe'ye göre whitelist'i ayarlıyoruz
});

// `rememberMe` durumunu `localStorage`'dan alıyoruz
const rememberMe = getRememberMe();
const persistConfig = createPersistConfig(rememberMe);

// Persisted reducer oluşturma
const persistedReducer = persistReducer(persistConfig, rootReducer as any);

//Redux store'u oluşturma
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(logger),
  devTools: process.env.NODE_ENV !== "production",
});

// Persistor'u oluşturma
export const persistor = persistStore(store);

export default store;
