import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "@/context/UserContext";
import { formatMoney, url } from "@/utils";
import { AddressDto } from "@/data/dtos/address.dto";
import { create } from "@/actions/orders.actions";
import IyzicoPaymentModal from "@/components/cart/IyzicoPaymentModal";
import { createUserAgreement, get } from "@/actions/agreements.action";
import DistanceSellingAgreement from "@/components/cart/DistanceSellingAgreement";
import { UserAgreementsData } from "@/data/dtos/user-agreements.dto";

export default function PaymentPage() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { items, total, paidTotal, tax, discount } = useSelector(
    (state: any) => state.cart
  );
  const { agreement } = useSelector((state: any) => state.agreements);
  const userContext = useContext(UserContext);
  const [shippingAddress, setShippingAddress] = useState<any>({});
  const [billingAddress, setBillingAddress] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<string | null>(null);
  const [checkoutFormContent, setCheckoutFormContent] = useState<string | null>(
    null
  );
  const [isCheckAgreement, setIsCheckAgreement] = useState(false);
  const { order } = useSelector((state: any) => state.order);
  const [isSameAddress, setIsSameAddress] = useState(true);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  //TODO: send just courses ids to backend calculate payment there again

  const { user } = userContext;

  const handleCheckout = async () => {
    const buyerId = user?._id;
    if (!buyerId) {
      throw new Error("User ID is required");
    }
    const checkoutObject = {
      price: total,
      paidPrice: paidTotal,
      currency: "TRY",
      paymentChannel: "WEB",
      installment: 1,
      buyer: buyerId,
      items: items?.map((item: any) => {
        const discount = item.price * (item.discountPercentage / 100);
        const itemDiscountedPrice = item.price - discount;
        const itemTax = itemDiscountedPrice * (item.tax / 100);
        return {
          course: item._id,
          quantity: 1,
          price: item.price,
          discount: discount,
          tax: itemTax,
          total: item.total,
        };
      }),
      shippingAddress: shippingAddress,
      billingAddress: billingAddress,
      status: "Pending",
    };
    console.log(checkoutObject);
    // Backend'den ödeme sayfası içeriğini almak
    const UserAgreementsData: UserAgreementsData = {
      user: user?._id!,
      agreement: agreement?._id,
      filledFields: {},
      content: content!,
    };
    await dispatch(createUserAgreement(UserAgreementsData) as any);
    await dispatch(create(checkoutObject) as any);
  };

  useEffect(() => {
    if (order?.data?.checkoutFormContent) {
      setCheckoutFormContent(order.data.checkoutFormContent);
    } else if (order?.data?.ThreeDSessionId) {
      navigation(url("Cart.Send-Payment"));
    }
  }, [order]);

  useEffect(() => {
    dispatch(get("676417ea096b007709d3432f") as any);
  }, [dispatch]);

  const handleAddressSelect = (address) => {
    if (isSameAddress) {
      setShippingAddress(address);
      setBillingAddress(address);
    }
    setShippingAddress(address);
  };

  return (
    <div>
      <DistanceSellingAgreement
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        user={user}
        items={items}
        paidTotal={paidTotal}
        shippingAddress={shippingAddress}
        billingAddress={billingAddress}
        setContent={setContent}
      />
      <section className="bg-white py-8 antialiased md:py-16">
        <h1 className="hidden">Ödeme Sayfası</h1>
        <div className="mx-auto max-w-screen-xl  bg-white rounded p-4 shadow">
          <ol className="items-center flex w-full max-w-2xl text-center text-sm font-medium text-gray-500  sm:text-base">
            <li className="after:border-1 flex items-center text-primary-700 after:mx-6 after:hidden after:h-1 after:w-full after:border-b after:border-gray-200  sm:after:inline-block sm:after:content-[''] md:w-full xl:after:mx-10">
              <Link to={url("Cart")}>
                <span className="flex items-center after:mx-2 after:text-gray-200 after:content-['/']  sm:after:hidden">
                  <svg
                    className="me-2 h-4 w-4 sm:h-5 sm:w-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  Sepet
                </span>
              </Link>
            </li>

            <li className="after:border-1 flex items-center text-primary-700 after:mx-6 after:hidden after:h-1 after:w-full after:border-b after:border-gray-200  sm:after:inline-block sm:after:content-[''] md:w-full xl:after:mx-10">
              <span className="flex items-center after:mx-2 after:text-gray-200 after:content-['/']  sm:after:hidden">
                <svg
                  className="me-2 h-4 w-4 sm:h-5 sm:w-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <p className=" w-max">Ödeme Yap</p>
              </span>
            </li>

            <li className="flex shrink-0 items-center">
              <svg
                className="me-2 h-4 w-4 sm:h-5 sm:w-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              Sipariş Detayı
            </li>
          </ol>

          <div className="mt-6 sm:mt-8 lg:flex lg:items-start lg:gap-12 xl:gap-16">
            <div className="min-w-0 flex-1 space-y-8">
              <div className="space-y-4">
                <div className="flex justify-between gap-4">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-900 ">
                      Sipariş Adresi
                    </h2>
                    <span className="text-gray-500">
                      Sadece sertifikalı atölyelerin sertifikaları kargo ile
                      gönderilmektedir.
                    </span>
                  </div>
                  <div className="flex gap-2 items-center">
                    <input
                      onChange={() => setIsSameAddress(!isSameAddress)}
                      type="checkbox"
                      checked={isSameAddress}
                      name="isSameAddress"
                      id="isSameAddress"
                    />
                    <label htmlFor="isSameAddress">
                      Faturamı aynı adrese gönder.
                    </label>
                  </div>
                </div>

                <div className={`grid grid-cols-2 gap-4 my-4 `}>
                  {(user?.addresses as AddressDto[])?.length > 0 ? (
                    (user?.addresses as AddressDto[])?.map(
                      (address: AddressDto) => (
                        <button
                          onClick={() => handleAddressSelect(address)}
                          key={address._id}
                          className={`rounded shadow bg-white relative ${
                            shippingAddress._id === address._id
                              ? "border-2 border-indigo-600"
                              : ""
                          }`}
                        >
                          <div className="p-4 flex flex-col">
                            <div className="w-full text-lg">
                              {address.title}
                            </div>
                            <div className="text-gray-500">
                              {address.address}
                            </div>
                            <div className="text-gray-500">
                              {address.contactName}
                            </div>
                            <div className="text-gray-500">
                              {address.isBillingAddress
                                ? "Fatura Adresi"
                                : "Kargo Adresi"}
                            </div>
                          </div>
                        </button>
                      )
                    )
                  ) : (
                    <Link
                      to={url("Profile.Addresses")}
                      state={{ from: "checkout" }}
                      className="border-blue-500 border-2 p-8 rounded-lg hover:scale-105"
                    >
                      Adres Eklemelisiniz
                    </Link>
                  )}
                </div>
              </div>
              {isSameAddress ? null : (
                <div className="space-y-4">
                  <h2 className="text-xl font-semibold text-gray-900 ">
                    Fatura Adresi
                  </h2>

                  <div className="grid grid-cols-2 gap-4 my-4">
                    {(user?.billingAddresses as AddressDto[])?.length > 0 ? (
                      (user?.billingAddresses as AddressDto[])?.map(
                        (address: AddressDto) => (
                          <button
                            onClick={() => setBillingAddress(address)}
                            key={address._id}
                            className={`rounded shadow bg-white relative ${
                              billingAddress._id === address._id
                                ? "border-2 border-indigo-600"
                                : ""
                            }`}
                          >
                            <div className="p-4 flex flex-col">
                              <div className="w-full text-lg">
                                {address.title}
                              </div>
                              <div className="text-gray-500">
                                {address.address}
                              </div>
                              <div className="text-gray-500">
                                {address.contactName}
                              </div>
                              <div className="text-gray-500">
                                {address.isBillingAddress
                                  ? "Fatura Adresi"
                                  : "Kargo Adresi"}
                              </div>
                            </div>
                          </button>
                        )
                      )
                    ) : (
                      <Link
                        to={url("Profile.Addresses")}
                        className="border-blue-500 border-2 p-8 rounded-lg hover:scale-105"
                      >
                        Adress Eklemelisiniz
                      </Link>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="mt-6 w-full space-y-6 sm:mt-8 lg:mt-0 lg:max-w-xs xl:max-w-md">
              <div className="flow-root">
                <div className="-my-3 divide-y divide-gray-200 ">
                  <dl className="flex items-center justify-between gap-4 py-3">
                    <dt className="text-base font-normal text-gray-500 ">
                      Fiyat
                    </dt>
                    <dd className="text-base font-medium text-gray-900 ">
                      {formatMoney(total)}
                    </dd>
                  </dl>

                  <dl className="flex items-center justify-between gap-4 py-3">
                    <dt className="text-base font-normal text-gray-500 ">
                      İndirim
                    </dt>
                    <dd className="text-base font-medium text-green-500">
                      {formatMoney(discount)}
                    </dd>
                  </dl>

                  <dl className="flex items-center justify-between gap-4 py-3">
                    <dt className="text-base font-normal text-gray-500 ">
                      Vergi
                    </dt>
                    <dd className="text-base font-medium text-gray-900 ">
                      {formatMoney(tax)}
                    </dd>
                  </dl>

                  <dl className="flex items-center justify-between gap-4 py-3">
                    <dt className="text-base font-bold text-gray-900 ">
                      Toplam Fiyat
                    </dt>
                    <dd className="text-base font-bold text-gray-900 ">
                      {formatMoney(paidTotal)}
                    </dd>
                  </dl>
                </div>
              </div>

              <div className="space-y-3">
                {user?.role === "student" || !user ? (
                  <button
                    type="submit"
                    className="flex w-full items-center disabled:bg-gray-500 disabled:cursor-none justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4  focus:ring-primary-300 "
                    disabled={
                      !user ||
                      !isCheckAgreement ||
                      !billingAddress._id ||
                      !shippingAddress._id
                        ? true
                        : false
                    }
                    onClick={handleCheckout}
                  >
                    {!user
                      ? "Giriş Yapmalısınız"
                      : !isCheckAgreement
                      ? "Lütfen Uzak Mesafe Satış Sözleşmesini Kabul Ediniz"
                      : !billingAddress._id && !shippingAddress._id
                      ? "Adres Seçiniz"
                      : "Ödeme Yap"}
                  </button>
                ) : (
                  <div className="text-sm font-normal text-gray-500 ">
                    Eğitmen hesapları satın alım gerçekleştiremez.
                  </div>
                )}

                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="terms"
                    onChange={(e) => {
                      setIsCheckAgreement(!isCheckAgreement);
                    }}
                    required
                  />
                  <label htmlFor="terms">
                    <button
                      className=" text-blue-500 underline"
                      onClick={() => setIsOpen(true)}
                    >
                      Uzak mesafe satış sözleşmesini
                    </button>{" "}
                    ve satış sözleşmesini kabul ediyorum. Atölyelerin süresinin
                    3 ay olduğundan bilgim var.
                  </label>
                </div>
                {!user && (
                  <p className="text-sm font-normal text-gray-500 ">
                    Bir veya daha fazla eğitim giriş yapmanızı gerektiriyor.
                    <Link
                      to={url("Auth.Login")}
                      title=""
                      className="font-medium text-primary-700 underline hover:no-underline"
                    >
                      Giriş yap veya kayıt ol.
                    </Link>
                    .
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {checkoutFormContent && (
        <IyzicoPaymentModal checkoutFormContent={checkoutFormContent} />
      )}
    </div>
  );
}
