import { remove } from "@/actions/users.actions";
import OptionsMenu from "@/components/core/TableOptionMenu";
import { url } from "@/utils";
import { Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function UserTable({ tableTitle, data }) {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const handleDelete = async (id) => {
    dispatch(remove(id) as any);
  };

  const columns: GridColDef[] = [
    {
      field: "no",
      headerName: "No",
      flex: 0.1,
    },
    {
      field: "buyer",
      headerName: "Kullanıcı",
      flex: 0.2,
    },
    { field: "email", headerName: "E-Posta", flex: 0.1 },
    {
      field: "phone",
      headerName: "Telefon",
      flex: 0.1,
    },
    {
      field: "role",
      headerName: "Rol",
      flex: 0.1,
      valueGetter: (param: any) => {
        return param == "admin"
          ? "Admin"
          : param == "teacher"
          ? "Eğitmen"
          : "Öğrenci";
      },
    },

    {
      field: "options",
      headerName: "Seçenekler",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation(); // Prevent row selection on click
          // You can manage the menu state in the parent or directly in the component
        };

        return (
          <Stack direction="row" spacing={1}>
            <OptionsMenu
              id={params.row.id}
              onEdit={() => {
                navigation(url("Admin.Users.Edit", { id: params.row.id }));
              }}
              onView={() => {
                navigation(url("Admin.Users.View", { id: params.row.id }));
              }}
              onDelete={handleDelete}
              onOpenMenu={handleMenuOpen} // Pass the handler to OptionsMenu
            />
          </Stack>
        );
      },
    },
  ];

  const pageSizeOptions = [25, 50, 100];

  return (
    <div className="bg-white rounded shadow p-4">
      <Typography className="w-full text-center my-4 font-bold">
        {tableTitle}
      </Typography>
      <div className="h-auto w-full p-2">
        <DataGrid
          rows={data}
          columns={columns}
          pageSizeOptions={pageSizeOptions}
          checkboxSelection
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: pageSizeOptions[0] },
            },
          }}
        />
      </div>
    </div>
  );
}
