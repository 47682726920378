import {
  FETCH_COURSES,
  FETCH_COURSES_SUCCESS,
  CREATE_COURSE_SUCCESS,
  UPDATE_COURSE_SUCCESS,
  REMOVE_COURSE_SUCCESS,
  CREATE_COURSE_FAILED,
  UPDATE_COURSE_FAILED,
  REMOVE_COURSE_FAILED,
  FETCH_COURSES_FAILED,
  FETCH_COURSE,
  ADD_RESOURCE_TO_COURSE_SECTION_FAILED,
  ADD_RESOURCE_TO_COURSE_SECTION_SUCCESS,
  ADD_SECTION_TO_COURSE_FAILED,
  REMOVE_SECTION_FROM_COURSE_FAILED,
  REMOVE_SECTION_FROM_COURSE_SUCCESS,
  ADD_SECTION_TO_COURSE_SUCCESS,
  UPDATE_COURSE_SECTION_FAILED,
  UPDATE_COURSE_SECTION_SUCCESS,
  FETCH_COURSE_SUCCESS,
  FETCH_COURSE_FAILED,
  REMOVE_RESOURCE_FROM_COURSE_SECTION_FAILED,
  UPDATE_RESOURCE_FROM_COURSE_SECTION_FAILED,
  UPDATE_RESOURCE_FROM_COURSE_SECTION_SUCCESS,
  ADD_REVIEW_TO_COURSE_SUCCESS,
  ADD_REVIEW_TO_COURSE_FAILED,
} from "../actions/type";
import { cloneDeep } from "lodash";
import { deepClone } from "@mui/x-data-grid/internals";

interface CoursesState {
  courses: any[];
  currentCourse: any;
  status: string;
  listStatus: string;
  error: any;
  accessToken: string | null;
  activeClasses: any[];
}

const initialState: CoursesState = {
  courses: [],
  currentCourse: null,
  status: "idle",
  listStatus: "idle",
  error: null,
  accessToken: null,
  activeClasses: [],
};

export default function coursesReducer(
  state = initialState,
  action: any
): CoursesState {
  const { type, payload } = action;

  switch (type) {
    case FETCH_COURSE:
      return {
        ...state,
        status: "loading",
      };

    case FETCH_COURSES:
      return {
        ...state,
        listStatus: "loading",
      };

    case FETCH_COURSES_SUCCESS:
      return {
        ...state,
        courses: Array.isArray(payload) ? payload : [],
        listStatus: "success",
      };

    case FETCH_COURSE_SUCCESS:
      return {
        ...state,
        currentCourse: deepClone(payload.data),
        status: "success",
      };

    case CREATE_COURSE_SUCCESS:
      return {
        ...state,
        courses: [...state.courses, cloneDeep(payload.data)],
        status: "success",
      };

    case UPDATE_COURSE_SUCCESS:
      return {
        ...state,
        courses: state.courses.map((course: any) =>
          course._id === payload._id ? cloneDeep(payload) : course
        ),
        currentCourse: state.currentCourse,
        status: "success",
      };

    case ADD_REVIEW_TO_COURSE_SUCCESS: {
      const updatedReviews = [
        ...(state.currentCourse?.data?.reviews || []),
        action.payload.review,
      ];

      return {
        ...state,
        currentCourse: {
          ...state.currentCourse,
          data: {
            ...state.currentCourse.data,
            reviews: updatedReviews,
          },
        },
        status: "success",
      };
    }

    case REMOVE_COURSE_SUCCESS:
      return {
        ...state,
        courses: state.courses.filter(
          (course: any) => course._id !== payload.data._id
        ),
        status: "success",
      };

    case ADD_SECTION_TO_COURSE_SUCCESS:
    case UPDATE_COURSE_SECTION_SUCCESS:
    case REMOVE_SECTION_FROM_COURSE_SUCCESS:
    case ADD_RESOURCE_TO_COURSE_SECTION_SUCCESS:
    case UPDATE_RESOURCE_FROM_COURSE_SECTION_SUCCESS: {
      if (!state.currentCourse || !state.currentCourse.data) {
        return {
          ...state,
          status: "error",
          error: "Current course data is missing",
        };
      }

      const updatedSections = state.currentCourse.data.sections.map((section) =>
        section._id === payload.sectionId
          ? {
              ...section,
              resources: section.resources
                ? [...section.resources, payload.newResource]
                : [payload.newResource],
            }
          : section
      );

      return {
        ...state,
        currentCourse: {
          ...state.currentCourse,
          data: {
            ...state.currentCourse.data,
            sections: updatedSections,
          },
        },
        courses: state.courses.map((course) =>
          course._id === state.currentCourse.data._id
            ? { ...course, sections: updatedSections }
            : course
        ),
        status: "success",
      };
    }

    case "GENERATE_LIVE_CLASS_TOKEN_SUCCESS":
      return {
        ...state,
        accessToken: payload,
        status: "success",
      };

    case "GET_ACTIVE_CLASSES_SUCCESS":
      console.log(payload);
      return {
        ...state,
        activeClasses: payload,
        status: "success",
      };

    case FETCH_COURSE_FAILED:
    case CREATE_COURSE_FAILED:
    case UPDATE_COURSE_FAILED:
    case REMOVE_COURSE_FAILED:
    case FETCH_COURSES_FAILED:
    case UPDATE_COURSE_SECTION_FAILED:
    case ADD_SECTION_TO_COURSE_FAILED:
    case REMOVE_SECTION_FROM_COURSE_FAILED:
    case ADD_RESOURCE_TO_COURSE_SECTION_FAILED:
    case UPDATE_RESOURCE_FROM_COURSE_SECTION_FAILED:
    case REMOVE_RESOURCE_FROM_COURSE_SECTION_FAILED:
    case ADD_REVIEW_TO_COURSE_FAILED:
      return {
        ...state,
        status: "failed",
        listStatus: "failed",
        error: payload,
      };

    default:
      return state;
  }
}
