import { get } from "@/actions/setting.action";
import { useLocationContext } from "@/context/LocationContext";
import { isValidLuhn } from "@/utils";
import { Modal } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PaymentForm = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const locationContext = useLocationContext();
  const { order } = useSelector((state: any) => state.order);
  const { currentSetting, status } = useSelector(
    (state: any) => state.settings
  );
  const [activePayment, setActivePayment] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [htmlResponse, setHtmlResponse] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cardType, setCardType] = useState("");
  const [showBack, setShowBack] = useState(false);

  useEffect(() => {
    if (status === "idle") {
      dispatch(get("paymentMethods") as any);
    }
  }, [status, dispatch]);

  useEffect(() => {
    if (currentSetting) {
      const activePayment = currentSetting.value.filter(
        (val) => val.isActive === true
      );
      setActivePayment(activePayment);
    }
  }, [currentSetting]);

  useEffect(() => {
    if (!order?.data?.ThreeDSessionId) {
      navigation(locationContext.previousPath || "/");
    }
  });

  const [formData, setFormData] = useState({
    ThreeDSessionId: order?.data?.ThreeDSessionId,
    CardHolderName: "",
    CardNo: "",
    ExpireMonth: "",
    ExpireYear: "",
    Cvv: "",
  });

  const [errors, setErrors] = useState({
    CardNo: "",
    ExpireMonth: "",
    ExpireYear: "",
    Cvv: "",
  });

  // Detect Card Type
  const detectCardType = (number: string) => {
    const cleanedNumber = number.replace(/\D/g, "");

    if (/^4/.test(cleanedNumber)) return "Visa";
    if (/^5[1-5]/.test(cleanedNumber)) return "MasterCard";
    if (/^3[47]/.test(cleanedNumber)) return "American Express";
    return "";
  };

  // Handle Input Change
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "CardNo") {
      setCardType(detectCardType(value));
    }
  };

  // Validate Expiration Date
  const isValidExpireDate = () => {
    const month = Number(formData.ExpireMonth);
    const year = Number(formData.ExpireYear);
    if (!month || !year || month < 1 || month > 12) return false;

    const currentYear = new Date().getFullYear() % 100;
    const currentMonth = new Date().getMonth() + 1;

    return (
      year > currentYear || (year === currentYear && month >= currentMonth)
    );
  };

  // Handle Form Submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let newErrors: any = {};

    if (!isValidLuhn(formData.CardNo)) {
      newErrors.CardNo = "Geçersiz kredi kartı numarası.";
    }

    // if (!isValidExpireDate()) {
    //   newErrors.ExpireMonth = "Geçersiz veya süresi dolmuş son kullanma tarihi.";
    // }

    if (!formData.Cvv || formData.Cvv.length < 3 || formData.Cvv.length > 4) {
      newErrors.Cvv = "CVV kodu 3 veya 4 haneli olmalıdır.";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;
    const formDataToSend = new FormData();
    formDataToSend.append("ThreeDSessionId", formData.ThreeDSessionId);
    formDataToSend.append("CardHolderName", formData.CardHolderName);
    formDataToSend.append("CardNo", formData.CardNo);
    formDataToSend.append(
      "ExpireDate",
      `${formData.ExpireMonth}/${formData.ExpireYear}`
    );
    formDataToSend.append("Cvv", formData.Cvv);
    console.log("endpoint:" + activePayment[0].endpoint, "data :", formData);
    try {
      const response = await axios.post(
        activePayment[0].endpoint + "ProcessCardForm",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      if (response.data) {
        console.log(response);
        setHtmlResponse(response.data);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // Güvenlik için sadece izin verilen domainlerden gelen mesajları işleme al
      console.log(event);
      if (event.origin !== process.env.REACT_APP_SERVER_URL) {
        return;
      }

      if (event.data?.redirectUrl) {
        window.location.href = event.data.redirectUrl; // Kullanıcıyı yönlendir
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      {/* Credit Card Visualizer */}
      <div className="relative w-96 h-56 perspective">
        <div
          className={`relative w-full h-full transition-transform duration-500 transform`}
        >
          {/* Front Side */}
          <div className="absolute w-full h-full bg-gradient-to-r from-blue-600 to-blue-400 rounded-lg shadow-lg p-6 text-white backface-hidden">
            <p className="text-sm">Kredi Kartı</p>
            <p className="text-xl font-semibold tracking-widest mt-4">
              {formData.CardNo
                ? formData.CardNo.replace(/(.{4})/g, "$1 ")
                : "**** **** **** ****"}
            </p>
            <div className="flex justify-between mt-4">
              <p>{formData.CardHolderName || "Kart Sahibi"}</p>
              <p>
                {formData.ExpireMonth || "MM"}/{formData.ExpireYear || "YY"}
              </p>
            </div>
            <p className="absolute bottom-4 right-6 text-lg">
              {cardType || "Card Type"}
            </p>
          </div>

          {/* Back Side */}
          <div
            className={`absolute  w-full h-full bg-gray-900 rounded-lg shadow-lg p-6 text-white transform rotate-y-180 backface-hidden ${
              showBack ? "block" : "hidden"
            }`}
          >
            <div className="w-full h-10 bg-black mt-4"></div>
            <p className="mt-4 text-right">CVV</p>
            <div className="w-16 h-10 bg-white text-black flex items-center justify-center mx-auto mt-2">
              {formData.Cvv ? "***" : "123"}
            </div>
          </div>
        </div>
      </div>

      {/* Payment Form */}
      <form
        onSubmit={handleSubmit}
        className="max-w-md w-full bg-white p-6 mt-6 rounded-lg shadow-lg"
      >
        <div className="mb-4">
          <label className="block text-gray-700 font-bold">
            Kart Sahibi Adı:
          </label>
          <input
            type="text"
            name="CardHolderName"
            value={formData.CardHolderName}
            onChange={handleChange}
            onFocus={() => setShowBack(false)}
            className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-bold">
            Kredi Kartı Numarası:
          </label>
          <input
            type="text"
            name="CardNo"
            value={formData.CardNo}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
            maxLength={19}
            placeholder="1234 5678 9012 3456"
            required
          />
          {errors.CardNo && (
            <p className="text-red-500 text-sm mt-1">{errors.CardNo}</p>
          )}
        </div>

        <div className="mb-4 flex space-x-2">
          <div className="w-1/2">
            <label className="block text-gray-700 font-bold">
              Son Kullanma Ayı:
            </label>
            <select
              name="ExpireMonth"
              value={formData.ExpireMonth}
              onChange={handleChange}
              className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
              required
            >
              <option value="">AA</option>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={String(i + 1).padStart(2, "0")}>
                  {String(i + 1).padStart(2, "0")}
                </option>
              ))}
            </select>
          </div>
          <div className="w-1/2">
            <label className="block text-gray-700 font-bold">Yıl:</label>
            <select
              name="ExpireYear"
              value={formData.ExpireYear}
              onChange={handleChange}
              className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
              required
            >
              <option value="">YY</option>
              {Array.from({ length: 11 }, (_, i) => {
                const currentYear = new Date().getFullYear() % 100; // Şu anki yılın son iki hanesi (ör: 25)
                const year = String(currentYear - 1 + i); // 2024'ü de eklemek için bir yıl geri al
                return (
                  <option key={i} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-bold">CVV:</label>
          <input
            type="text"
            name="Cvv"
            value={formData.Cvv}
            onChange={handleChange}
            onFocus={() => setShowBack(true)} // Flip card to back
            onBlur={() => setShowBack(false)} // Flip card back to front
            className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
            maxLength={4}
            placeholder="123"
            required
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-all"
        >
          Ödeme Yap
        </button>
      </form>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="max-w-[500px] mx-auto my-auto flex items-center justify-center"
      >
        <div className="p-6 bg-white rounded-lg shadow-lg w-full">
          <h2 className="text-lg font-semibold">3D Secure Doğrulama</h2>
          <p className="text-gray-600">
            Lütfen bankanın doğrulama işlemini tamamlayın.
          </p>
          {/* Bankadan dönen HTML içeriğini `iframe` içinde çalıştırıyoruz */}
          {htmlResponse && (
            <iframe
              title="3D Secure"
              srcDoc={htmlResponse}
              width="100%"
              height="500px"
              frameBorder="0"
            />
          )}
          <button
            onClick={() => setIsModalOpen(false)}
            className="mt-4 bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600"
          >
            Kapat
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default PaymentForm;
