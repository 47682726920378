import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ReactComponent as UsersIcon } from "@/assets/icons/user.svg";
import { ReactComponent as MessageIcon } from "@/assets/icons/message.svg";
import { ReactComponent as ChartIcon } from "@/assets/icons/chart.svg";
import { ReactComponent as DollarIcon } from "@/assets/icons/dollar.svg";

const iconMap: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
  faMessage: MessageIcon,
  faBox: ChartIcon,
  faUser: UsersIcon,
  faDollar: DollarIcon,
};

export default function ReportCard({ metric }) {
  const IconComponent = iconMap[metric.icon] || MessageIcon;
  return (
    <div className="flex p-6 bg-white rounded shadow-md border-blue-600  border-t-4">
      <div className="w-min">
        <IconComponent className="text-blue-500 hover:text-blue-700 w-6 h-6" />
      </div>
      <div
        key={metric.id}
        className="flex flex-col items-center justify-center text-center w-full"
      >
        <h2 className="text-lg font-medium text-gray-500">{metric.title}</h2>
        <p className="text-2xl font-bold">{metric.value}</p>
        {metric.trend === "up" && (
          <span className="text-green-500 mt-2">⬆ Artış</span>
        )}
        {metric.trend === "down" && (
          <span className="text-red-500 mt-2">⬇ Azalış</span>
        )}
        {metric.trend === "neutral" && (
          <span className="text-gray-500 mt-2">- Sabit</span>
        )}
      </div>
    </div>
  );
}
