import { UserContext } from "@/context/UserContext";
import { OrderStatus } from "@/data/enums/order-status.enum";
import { formatMoney, url } from "@/utils";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { head } from "lodash";
import React, { useContext } from "react";
import { render } from "react-dom";
import { Link } from "react-router-dom";

export default function OrdersChart({ recentOrders }) {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }
  const user = userContext.user;
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Sipariş İd ",
      flex: 0.1,
    },
    ...(user?.role === "admin"
      ? [
          {
            field: "buyer",
            headerName: "Satın Alan Kişi",
            flex: 0.1,
            renderCell: (params) => {
              console.log(params);
              return (
                <Link
                  to={url("Admin.Users.View", { id: params.row.buyer._id })}
                >
                  {params.row.buyer.name} {params.row.buyer.surname}
                </Link>
              );
            },
          },
          {
            field: "email",
            headerName: "Satın Alan Kişinin E-Posta",
            flex: 0.1,
          },
          {
            field: "phone",
            headerName: "Satın Alan Kişinin Telefon",
            flex: 0.1,
          },
          {
            field: "price",
            headerName: "Toplam Liste Fiyatı",
            flex: 0.1,
            valueGetter: (param: any) => {
              const saledCourse: any = param.items.filter(
                (course: any) => course.course
              );
              return formatMoney(
                saledCourse.reduce((a: any, b: any) => a + b.price, 0)
              );
            },
          },
          {
            field: "paidPriceWithTax",
            headerName: "Satış Fiyatı",
            flex: 0.1,
            valueGetter: (param: any) => {
              const saledCourse: any = param.items.filter(
                (course: any) => course.course
              );
              return formatMoney(
                saledCourse.reduce(
                  (a: any, b: any) => a + (b.price - b.discount) + b.tax,
                  0
                )
              );
            },
          },
        ]
      : [
          {
            field: "price",
            headerName: "Yapılan Satın Alım",
            flex: 0.1,
            valueGetter: (param: any) => {
              const saledCourse: any = param.items.filter((course: any) =>
                course.course.instructors.includes(user?._id)
              );
              return formatMoney(
                saledCourse.reduce((a: any, b: any) => a + b.price, 0)
              );
            },
          },
          {
            field: "paidPrice",
            headerName: "Ödenen Miktar",
            flex: 0.1,
            valueGetter: (param: any) => {
              const saledCourse: any = param.items.filter((course: any) =>
                course.course.instructors.includes(user?._id)
              );
              return formatMoney(
                saledCourse.reduce(
                  (a: any, b: any) => a + b.price - b.discount,
                  0
                )
              );
            },
          },
        ]),

    {
      field: "status",
      headerName: "Durum",
      flex: 0.1,
      renderCell: (params) => {
        const statusMap = {
          [OrderStatus.PAID]: {
            className: "bg-green-500",
            text: "Ödeme Gerçekleşti",
          },
          [OrderStatus.PENDING]: {
            className: "bg-yellow-400 text-black",
            text: "Beklemede",
          },
          default: {
            className: "bg-red-600",
            text: "İptal Edildi",
          },
        };
        const { className, text } =
          statusMap[params.row.status] || statusMap.default;
        return (
          <div
            className={`text-white flex justify-center font-bold ${className}`}
          >
            {text}
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Satış Tarih",
      flex: 0.1,
      valueFormatter: (params) => {
        const date = new Date(params);
        if (isNaN(date.getTime())) return "Invalid time value";
        return new Intl.DateTimeFormat("tr-TR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          timeZone: "Europe/Istanbul",
        }).format(date);
      },
    },
  ];
  const [columnState, setColumns] = React.useState(columns);

  const pageSizeOptions = [5, 25, 50, 100];

  return (
    <section className="bg-white rounded shadow-md p-6">
      <h3 className="text-lg font-bold mb-4">Son Siparişler</h3>
      {/* //TODO: hange table titles and add status change order ids to */}
      <DataGrid
        rows={recentOrders}
        columns={columns}
        pageSizeOptions={pageSizeOptions}
        checkboxSelection
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: pageSizeOptions[0] },
          },
        }}
      />
    </section>
  );
}
