import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Drawer,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import { ResourceDto } from "@/data/dtos/resource.dto";

export default function CourseSection({
  sections,
  setCurrentResource,
  setCurrentSection,
  courseProgress,
}) {
  const [selectedResource, setSelectedResource] = useState<ResourceDto | null>(
    null
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Burger menü kontrolü

  return (
    <div className="w-full">
      {/* Küçük ekranlar için burger menu ikonu */}
      <div className="lg:hidden">
        <IconButton onClick={() => setIsMenuOpen(true)}>
          <MenuIcon fontSize="large" />
        </IconButton>
      </div>

      {/* Drawer (Yan Menü) */}
      <Drawer
        anchor="left"
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <div className="w-64 p-4">
          <Typography variant="h6" className="pb-4">
            Kurs Bölümleri
          </Typography>
          <div className="overflow-y-auto max-h-[80vh]">
            {sections
              ?.sort((a, b) => a.order - b.order)
              .map((section, index) => (
                <Accordion
                  key={section._id ?? index}
                  className="border rounded"
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{section.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex flex-col gap-2">
                      {section.resources
                        ?.sort((a, b) => a.order - b.order)
                        .map((resource) => (
                          <button
                            key={resource._id}
                            className="flex w-full"
                            style={{
                              cursor: "pointer",
                              backgroundColor:
                                selectedResource?._id === resource._id
                                  ? "#f1f1f1"
                                  : "",
                            }}
                            onClick={() => {
                              setSelectedResource(resource);
                              setCurrentResource(resource);
                              setCurrentSection(section);
                              setIsMenuOpen(false); // Menü kapanmalı
                            }}
                          >
                            <div className="flex gap-4 p-4">
                              <input
                                type="checkbox"
                                name="isCompleted"
                                checked={courseProgress?.some((progress) =>
                                  progress.resources?.some(
                                    (rsc) =>
                                      rsc.resourceId === resource._id &&
                                      rsc.isCompleted
                                  )
                                )}
                              />
                              <span className="w-full text-left">
                                <Typography>{resource.title}</Typography>
                              </span>
                            </div>
                          </button>
                        ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        </div>
      </Drawer>

      {/* Büyük ekranlar için normal liste */}
      <div className="hidden lg:block w-full overflow-y-scroll pe-4">
        {sections
          ?.sort((a, b) => a.order - b.order)
          .map((section, index) => (
            <Accordion key={section._id ?? index} className="border rounded">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{section.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-col gap-2">
                  {section.resources
                    ?.sort((a, b) => a.order - b.order)
                    .map((resource) => (
                      <button
                        key={resource._id}
                        className="flex w-full"
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            selectedResource?._id === resource._id
                              ? "#f1f1f1"
                              : "",
                        }}
                        onClick={() => {
                          setSelectedResource(resource);
                          setCurrentResource(resource);
                          setCurrentSection(section);
                        }}
                      >
                        <div className="flex gap-4 p-4">
                          <input
                            type="checkbox"
                            name="isCompleted"
                            checked={courseProgress?.some((progress) =>
                              progress.resources?.some(
                                (rsc) =>
                                  rsc.resourceId === resource._id &&
                                  rsc.isCompleted
                              )
                            )}
                          />
                          <span className="w-full text-left">
                            <Typography>{resource.title}</Typography>
                          </span>
                        </div>
                      </button>
                    ))}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
    </div>
  );
}
