import { UserContext } from "@/context/UserContext";
import { OrderStatus } from "@/data/enums/order-status.enum";
import { formatMoney, url } from "@/utils";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { head } from "lodash";
import React, { useContext } from "react";
import { render } from "react-dom";
import { Link } from "react-router-dom";

export default function TeacherOrdersChart({ recentOrders }) {
  const columns: GridColDef[] = [
    {
      field: "no",
      headerName: "No",
      flex: 0.1,
    },
    {
      field: "month",
      headerName: "Satıldığı Ay",
      flex: 0.1,
    },
    {
      field: "name",
      headerName: "Atölye Adı",
      flex: 0.3,
    },
    {
      field: "sales",
      headerName: "Satış Adedi",
      flex: 0.1,
    },
    {
      field: "revenue",
      headerName: "Satış Fiyatı",
      flex: 0.1,
      type: "number",
      valueFormatter: (params: any) => formatMoney(params),
    },
    {
      field: "tax",
      headerName: "Toplam Vergi",
      flex: 0.1,
      type: "number",
      valueFormatter: (params: any) => formatMoney(params),
    },
    {
      field: "order",
      headerName: "Tahsil Edilen Toplam Tutar",
      flex: 0.2,
      type: "number",
      renderCell: (params) => formatMoney(params.row.revenue + params.row.tax),
    },
  ];
  const [columnState, setColumns] = React.useState(columns);

  const pageSizeOptions = [5, 25, 50, 100];

  return (
    <section className="bg-white rounded shadow-md p-6">
      <h3 className="text-lg font-bold mb-4">Son Siparişler</h3>
      {/* //TODO: hange table titles and add status change order ids to */}
      <DataGrid
        rows={recentOrders}
        columns={columns}
        pageSizeOptions={pageSizeOptions}
        checkboxSelection
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: pageSizeOptions[0] },
          },
        }}
      />
    </section>
  );
}
