import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApplicationLogo from "./ApplicationLogo";
import { url } from "@/utils";
import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import { createClient } from "smtpexpress";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "@/actions/setting.action";

interface SuggesTeacherProps {
  name: string;
  surname: string;
  email: string;
  phone?: string;
  suggestedTeacherName: string;
  suggestedTeacherLink: string;
}

interface SuggestCourseProps {
  name: string;
  surname: string;
  email: string;
  phone?: string;
  suggestedCourseName: string;
  suggestedCourseDescription: string;
}

export default function Footer() {
  // const smtpexpressClient = createClient({
  //   projectId: process.env.REACT_APP_EXPRESS_PROJECT_ID ?? "",
  //   projectSecret: process.env.REACT_APP_EXPRESS_PROJECT_SECRET ?? "",
  // });
  const dispatch = useDispatch();
  const { settings, listStatus } = useSelector((state: any) => state.settings);
  const [allSettings, setAllSettings] = useState(settings);
  const [socialLinks, setSocialLinks] = useState<any>([]);
  const currentYear = new Date().getFullYear();

  const [suggestTeacherModalOpen, setSuggestTeacherModalOpen] = useState(false);
  const [suggestCourseModalOpen, setSuggestCourseModalOpen] = useState(false);
  const [teacherFormData, setTeacherFormData] = useState<SuggesTeacherProps>({
    name: "",
    surname: "",
    email: "",
    phone: "",
    suggestedTeacherName: "",
    suggestedTeacherLink: "",
  });

  const [courseFormData, setCourseFormData] = useState<SuggestCourseProps>({
    name: "",
    surname: "",
    email: "",
    phone: "",
    suggestedCourseName: "",
    suggestedCourseDescription: "",
  });

  const handleTeacherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTeacherFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCourseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCourseFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTeacherSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log("Teacher Suggestion Data:", teacherFormData);
    // smtpexpressClient.sendApi.sendMail({
    //   subject: "Eğitmen Öneri",
    //   message: JSON.stringify(teacherFormData),
    //   sender: {
    //     name: teacherFormData.name,
    //     email: teacherFormData.email,
    //   },
    //   recipients: process.env.REACT_APP_MAIL_USERNAME ?? "",
    // });
  };

  const handleCourseSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log("Course Suggestion Data:", courseFormData);
  };

  useEffect(() => {
    if (listStatus === "idle") {
      dispatch(getAll() as any);
    }
  }, [listStatus, dispatch]);

  useEffect(() => {
    const socialLinksSetting = settings.find(
      (setting: any) => setting.key === "socialLinks"
    );
    if (socialLinksSetting) {
      setAllSettings(settings);
      setSocialLinks(socialLinksSetting.value);
    }
  }, [settings]);

  return (
    <footer className="bg-white border-t shadow">
      <Modal open={suggestTeacherModalOpen}>
        <Box
          sx={{
            maxWidth: 600,
            mx: "auto",
            mt: 5,
            backgroundColor: "white",
            borderRadius: "8px",
          }}
        >
          <Box mb={4} p={3} boxShadow={3}>
            <Typography variant="h5" mb={2} textAlign="center">
              Eğitmen Öner
            </Typography>
            <form onSubmit={handleTeacherSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Adınız"
                    name="name"
                    value={teacherFormData.name}
                    onChange={handleTeacherChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Soyadınız"
                    name="surname"
                    value={teacherFormData.surname}
                    onChange={handleTeacherChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="E-Posta Adresiniz"
                    name="email"
                    type="email"
                    value={teacherFormData.email}
                    onChange={handleTeacherChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Telefon Numaranız (Opsiyonel)"
                    name="phone"
                    value={teacherFormData.phone}
                    onChange={handleTeacherChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Önerilen Eğitmen Adı"
                    name="suggestedTeacherName"
                    value={teacherFormData.suggestedTeacherName}
                    onChange={handleTeacherChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Eğitmen Hakkında Bağlantı Adresi (Linkedin, Twitter, vb.)"
                    name="suggestedTeacherLink"
                    value={teacherFormData.suggestedTeacherLink}
                    onChange={handleTeacherChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", gap: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Eğitmen Öner!
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => setSuggestTeacherModalOpen(false)}
                  >
                    Vazgeç
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>
      <Modal open={suggestCourseModalOpen}>
        <Box
          sx={{
            maxWidth: 600,
            mx: "auto",
            mt: 5,
            backgroundColor: "white",
            borderRadius: "8px",
          }}
        >
          <Box p={3} boxShadow={3}>
            <Typography variant="h5" mb={2} textAlign="center">
              Eğitim Öner
            </Typography>
            <form onSubmit={handleCourseSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Adınız"
                    name="name"
                    value={courseFormData.name}
                    onChange={handleCourseChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Soyadınız"
                    name="surname"
                    value={courseFormData.surname}
                    onChange={handleCourseChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="E-Posta Adresiniz"
                    name="email"
                    type="email"
                    value={courseFormData.email}
                    onChange={handleCourseChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Telefon numaranız (Opsiyonel)"
                    name="phone"
                    value={courseFormData.phone}
                    onChange={handleCourseChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Önerilen Eğitim Adı"
                    name="suggestedCourseName"
                    value={courseFormData.suggestedCourseName}
                    onChange={handleCourseChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Önerilen Kurs Açıklaması"
                    name="suggestedCourseDescription"
                    value={courseFormData.suggestedCourseDescription}
                    onChange={handleCourseChange}
                    multiline
                    rows={4}
                    required
                  />
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", gap: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Eğitimi Öner!
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => setSuggestCourseModalOpen(false)}
                  >
                    Vazgeç
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>
      <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-16 sm:px-6 lg:space-y-16 lg:px-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="text-teal-600" style={{ width: "15rem" }}>
            <ApplicationLogo className="" />
          </div>

          <ul className="mt-8 flex justify-start gap-6 sm:mt-0 sm:justify-end pe-10">
            {socialLinks.map((link) => (
              <Link
                key={link.name}
                to={link.href}
                rel="noreferrer"
                target="_blank"
                className="text-orange-50 transition hover:opacity-75"
              >
                <span className="sr-only">{link.name}</span>
                <div dangerouslySetInnerHTML={{ __html: link.icon }}></div>
              </Link>
            ))}
          </ul>
        </div>

        <div
          className="sm:flex sm:items-center mt-0 justify-end"
          style={{ marginTop: "0px" }}
        >
          <div className="flex justify-end">
            <div className="flex gap-2 hover:scale-105">
              <Link to="saymedya.com/">
                <img src="/logo2.jpg" alt="logo" className="w-64" />
              </Link>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-8 border-t border-gray-100 pt-8 sm:grid-cols-2 lg:grid-cols-4 lg:pt-16">
          <div className="ps-8">
            <p className="font-medium text-gray-900">Hizmetler</p>

            <ul className="mt-6 space-y-4 text-sm ">
              <li>
                <Link
                  to="#"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  Atölyeler
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  Kurgusal Etkinlik
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  Birebir Atölyeler
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  Marka ve İletişim Danışmanlığı
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  Yayıncılık
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  Danışmanlık
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  Etkinlik ve Organizasyon
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <p className="font-medium text-gray-900">İstek Ve Öneriler</p>

            <ul className="mt-6 space-y-4 text-sm">
              <li>
                <button
                  onClick={() =>
                    setSuggestCourseModalOpen(!suggestCourseModalOpen)
                  }
                  className="text-gray-700 transition hover:opacity-75"
                >
                  Atölye Öner
                </button>
              </li>

              <li>
                <button
                  onClick={() =>
                    setSuggestTeacherModalOpen(!suggestTeacherModalOpen)
                  }
                  className="text-gray-700 transition hover:opacity-75"
                >
                  Eğitmen Öner
                </button>
              </li>

              <li>
                <button className="text-gray-700 transition hover:opacity-75">
                  İstek ve Öneri
                </button>
              </li>
            </ul>
          </div>

          <div>
            <p className="font-medium text-gray-900">Yararlı Linkler</p>

            <ul className="mt-6 space-y-4 text-sm">
              <li>
                <Link
                  to={url("Home.Contact")}
                  className="text-gray-700 transition hover:opacity-75"
                >
                  İletişim
                </Link>
              </li>

              <li>
                <Link
                  to={url("Home.Contact")}
                  className="text-gray-700 transition hover:opacity-75"
                >
                  SSS
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  Canlı Sohbet
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <p className="font-medium text-gray-900">Yasal Bilgiler</p>

            <ul className="mt-6 space-y-4 text-sm">
              <li>
                <Link
                  to={url("Policies.Return-Policy")}
                  className="text-gray-700 transition hover:opacity-75"
                >
                  İade Politikası
                </Link>
              </li>

              <li>
                <Link
                  to={url("Policies.Refund-Policy")}
                  className="text-gray-700 transition hover:opacity-75"
                >
                  Geri Ödeme Politikası
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  Müşteri Memnuniyetleri
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <p className="text-center font-body ">
          <span className=" text-secondary">
            SAYCO, Saymedya ve Sayda Yayıncılık markasıdır.
          </span>
        </p>

        <p className="text-xs text-gray-500">
          &copy; {currentYear} Rovet Veri Denizi Yazılım LTD. ŞTİ. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
}
