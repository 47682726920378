import React, { useEffect, useState } from "react";
import { TextField, Box, Typography, Button } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { create, remove, upload } from "@/actions/image.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

interface ImagesUploadProps {
  images: any[];
  onChange: (field: string, value: any) => void;
  title?: string;
}

const UploadSection: React.FC<ImagesUploadProps> = ({
  images,
  onChange,
  title,
}) => {
  console.log(images);
  const dispatch = useDispatch();
  const [uploadedImages, setUploadedImages] = useState<any[]>(images || []);
  const [altText, setAltText] = useState("");

  const { URL, currentImage } = useSelector((state: any) => ({
    URL: state.uploads.URL,
    currentImage: state.images.currentImage,
  }));

  useEffect(() => {
    onChange("images", uploadedImages);
  }, [uploadedImages]);

  useEffect(() => {
    setUploadedImages(images);
  }, [images]);

  const handleAltChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAltText(e.target.value);

  const handleDeleteImage = (id: string) => {
    setUploadedImages((prev) => prev.filter((image) => image._id !== id));
  };

  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(upload(formData) as any);
    });
  };

  const handleSubmit = () => {
    if (URL) {
      const formData = { altText, url: URL };
      dispatch(create(formData) as any);
      setAltText("");
    }
  };

  useEffect(() => {
    if (currentImage) {
      setUploadedImages((prev) => [...prev, currentImage]);
    }
  }, [currentImage]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });

  return (
    <Box>
      <Box
        sx={{
          border: "2px solid gray",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 2,
          padding: 4,
          borderRadius: 5,
        }}
      >
        <Typography variant="h3">{title ?? "Atölye İmajları"}</Typography>
        <Box>
          <Box
            {...getRootProps()}
            sx={{ border: "2px dashed gray", padding: 2, textAlign: "center" }}
          >
            <input {...getInputProps()} />
            <Typography>
              Görsel yüklemek için tıklayın veya sürükleyin
            </Typography>
            {URL && <img src={URL} alt="preview" width={200} />}
          </Box>
          <TextField
            label="Alt Metin"
            fullWidth
            margin="normal"
            value={altText}
            onChange={handleAltChange}
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Kaydet
          </Button>
        </Box>
        <Box>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: "16px",
              maxHeight: "400px",
              overflowY: "scroll",
            }}
          >
            {uploadedImages?.map((image) => (
              <div
                key={image?._id}
                style={{
                  position: "relative",
                  background: "#f7f7f7",
                  borderRadius: "8px",
                  boxShadow: "0px 1px 3px rgba(0,0,0,0.2)",
                  padding: "8px",
                }}
              >
                {image?.url && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={image?.url}
                      alt={image?.altText}
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                    <span>{image?.altText}</span>
                  </div>
                )}
                <button
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    background: "red",
                    color: "white",
                    borderRadius: "4px",
                    padding: "8px",
                  }}
                  onClick={() => handleDeleteImage(image?._id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            ))}
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default UploadSection;
