import { apiClient } from "./base.service";

const invoiceService = {
  create: async (payload: any) => await apiClient.post("/invoices", payload),
  getAll: async () => await apiClient.get("/invoices"),
  get: async (id: string) => await apiClient.get("/invoices/" + id),
  update: async (id: string, payload: any) =>
    await apiClient.put("/invoices/" + id, payload),
  delete: async (id: string) => await apiClient.delete("/invoices/" + id),
  getInvoice: async (payload: any) =>
    await apiClient.get("/invoices/pdf", payload),
  getInvoiceZip: async (payload: any) =>
    await apiClient.get("/invoices/zip", payload),
  getInvoiceCsv: async (payload: any) =>
    await apiClient.get("/invoices/csv", payload),
  getInvoiceXlsx: async (payload: any) =>
    await apiClient.get("/invoices/xlsx", payload),
};

export default invoiceService;
