import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TeacherView from "./TeacherView";
import UserView from "./UserView";
import { get } from "@/actions/users.actions";

export default function TeacherPerformance() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { currentUser: teacher } = useSelector((state: any) => state.users);

  useEffect(() => {
    dispatch(get(id!) as any);
  }, [dispatch, id]);

  console.log(teacher);
  if (teacher?.role === "teacher" || teacher?.role === "admin")
    return <TeacherView id={id} teacher={teacher} />;

  return (
    <>
      <UserView />
    </>
  );
}
