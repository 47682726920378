import { getAll, remove } from "@/actions/users.actions";
import UserTable from "@/components/auth/admin/user/UserTable";
import Table from "@/components/core/Table";
import { UserData } from "@/data/dtos/user.dto";
import { url } from "@/utils";
import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function UserList() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { users } = useSelector((state: any) => state.users);
  const [students, setStudents] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    let isMounted = true;
    const fetchUsers = async () => {
      if (isMounted) {
        dispatch(getAll() as any);
      }
      setLoading(false);
    };

    fetchUsers();
  }, [dispatch]);

  const handleDelete = async (id) => {
    dispatch(remove(id) as any);
  };

  useEffect(() => {
    if (users.length !== 0) {
      setStudents(users.filter((user) => user.role === "student"));
    }
  }, [users]);

  if (loading) {
    return <Skeleton count={10} />;
  }

  if (users.length !== 0) {
    const rows = students?.map((item: UserData, index) => ({
      id: item._id,
      no: index + 1,
      buyer: item.name + " " + item.surname,
      email: item.email,
      phone: item.phone,
      role: item.role,
      status: item.status,
    }));

    return <UserTable tableTitle="Tüm Kullanıcılar" data={rows} />;
  }

  return <div>UserList</div>;
}
