import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAuth } from "../../../../../actions/courses.actions";
import { CourseDto } from "@/data/dtos/course.dto";
import CourseTable from "@/components/auth/admin/courses/CourseTable";
import CreateCourseCard from "@/components/auth/admin/courses/CreateCourseCard";
import { CourseStatus } from "@/data/enums/course-status.enum";
import Skeleton from "react-loading-skeleton";

export default function CourseList() {
  const dispatch = useDispatch();
  const allCourses = useSelector((state: any) => state.courses);
  const { user } = useSelector((state: any) => state.auth);
  const [coursesByInstructor, setCoursesByInstructor] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const fetchCourses = async () => {
      await dispatch(getAllAuth() as any);
      if (
        isMounted &&
        allCourses?.courses?.length > 0 &&
        user.role === "teacher"
      ) {
        const userId = user._id;
        const courses = allCourses.courses.filter((course) =>
          course.instructors.some(
            (instructor: any) =>
              instructor._id === userId || instructor === userId
          )
        );
        setCoursesByInstructor(courses);
      }
      setLoading(false);
    };
    fetchCourses();
    return () => {
      isMounted = false;
    };
  }, [dispatch, user._id, user.role]);

  if (loading) {
    return <Skeleton height={400} />;
  }
  if (coursesByInstructor.length > 0) {
    const rows = coursesByInstructor.map((item: CourseDto, index) => ({
      id: item._id,
      no: index + 1, // Her satıra benzersiz bir ID
      title: item.title,
      saleStart: item.startDate,
      level: item.level,
      price: item.price,
      discount: item.price * (item.discountPercentage / 100),
      tax: item.tax,
      status: item.status,
      isOnSale: item.isOnSale,
      thumbnail: item.images?.[0].url,
      categories: item.categories,
    }));

    return <CourseTable tableTitle="Oluşturulan Atölyeler" data={rows} />;
  }
  return <CreateCourseCard title="Atölye bulunamadı" cta="Atölye Ekle" />;
}
