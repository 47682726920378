import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface PrimaryButtonProps {
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: any;
  type?: any;
}

export default function PrimaryButton({
  className = "",
  icon = null,
  type = "button",
  disabled,
  children,
  ...props
}: PrimaryButtonProps) {
  return (
    <button
      {...props}
      className={
        `inline-flex items-center px-4 py-2 bg-gray-800 dark:bg-gray-200 border border-transparent rounded-md font-semibold text-xs text-white dark:text-gray-800 uppercase tracking-widest hover:bg-gray-700 dark:hover:bg-white focus:bg-gray-700 dark:focus:bg-white active:bg-gray-900 dark:active:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 transition ease-in-out duration-150 ${
          disabled && "opacity-25"
        } ` + className
      }
      disabled={disabled}
      type={type}
    >
      {icon ? <FontAwesomeIcon icon={icon} /> : ""}
      {children}
    </button>
  );
}
