import addressService from "@/services/address.service";
import { setError, setMessage } from "./message";

export const create = (payload: any) => async (dispatch: any) => {
  await addressService
    .create(payload)
    .then((response: any) => {
      dispatch({ type: "CREATE_ADDRESS_SUCCESS", payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: "CREATE_ADDRESS_FAIL" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const getAll = () => async (dispatch: any) => {
  await addressService
    .getAll()
    .then((response: any) => {
      dispatch({
        type: "FETCH_ADDRESSES_SUCCESS",
        payload: response.data.data,
      });
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: "FETCH_ADDRESSES_FAILED" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const get = (id: string) => async (dispatch: any) => {
  await addressService
    .get(id)
    .then((response: any) => {
      dispatch({
        type: "FETCH_ADDRESS_SUCCESS",
        payload: response.data.data,
      });
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: "FETCH_ADDRESS_FAILED" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const getByContact = (id: string) => async (dispatch: any) => {
  await addressService
    .findByContact(id)
    .then((response: any) => {
      dispatch({
        type: "FETCH_ADDRESSES_SUCCESS",
        payload: response.data.data,
      });
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: "FETCH_ADDRESSES_FAILED" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const update = (id: string, payload: any) => async (dispatch: any) => {
  await addressService
    .update(id, payload)
    .then((response: any) => {
      dispatch({ type: "UPDATE_ADDRESS_SUCCESS", payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: "UPDATE_ADDRESS_FAILED" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const remove = (id: string) => async (dispatch: any) => {
  await addressService
    .remove(id)
    .then((response: any) => {
      dispatch({ type: "DELETE_ADDRESS_SUCCESS", payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: "DELETE_ADDRESS_FAILED" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};
