import { apiClient } from "./base.service";

const slidersService = {
  create: async (payload: any) => await apiClient.post("/sliders", payload),
  getAll: async () => await apiClient.get("/sliders"),
  get: async (id: string) => await apiClient.get("/sliders/" + id),
  update: async (id: string, payload: any) =>
    await apiClient.patch("/sliders/" + id, payload),
  remove: async (id: string) => await apiClient.delete("/sliders/" + id),
};

export default slidersService;
