import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../actions/authAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCertificate,
  faFileWord,
  faFolder,
  faGear,
  faGraduationCap,
  faImages,
  faList,
  faPager,
  faPieChart,
  faPlus,
  faPowerOff,
  faUser,
  faUsers,
  faUsersRectangle,
} from "@fortawesome/free-solid-svg-icons";
import Accordion from "./AdminAccordion";
import { url } from "@/utils";
import MenuItems from "./core/MenuItems";
import { UserContext } from "@/context/UserContext";
import { FaPage4 } from "react-icons/fa";

export default function AdminSideMenu() {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  const { user } = userContext;

  const [isOpen, setIsOpen] = React.useState(false);
  const handleLogout = (e: any) => {
    e.preventDefault();
    localStorage.setItem("rememberMe", "false");
    dispatch(logout(user?.session?._id!) as any);
    navigator("/");
  };

  return (
    <div className="relative w-full">
      <div
        className={`min-h-screen h-auto bg-white w-full fixed md:static transform z-50 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 transition-transform duration-300 ease-in-out`}
      >
        <div className="p-4">
          <ul className="flex flex-col">
            <MenuItems
              title="Panel"
              icon={faPieChart}
              to={url("Admin.Dashboard")}
            />

            {/* Course section */}
            {user!.role === "teacher" && (
              <div>
                <Accordion title="Atölyeler">
                  <MenuItems
                    to={url("Admin.Courses.Create")}
                    title="Atölye Oluştur"
                    icon={faGraduationCap}
                  />
                  <MenuItems
                    to={url("Admin.Courses")}
                    title="Oluşturulan Atölyeler"
                    icon={faList}
                  />
                </Accordion>
              </div>
            )}

            {/* Course section */}
            {user!.role === "admin" && (
              <div>
                <Accordion title="Atölyeler">
                  <MenuItems
                    to={url("Admin.Courses.Course-List")}
                    title="Aktif Atölyeler"
                    icon={faList}
                  />

                  <MenuItems
                    to={url("Admin.Courses.Pending-Courses")}
                    title="Onay Bekleyen Atölyeler"
                    icon={faList}
                  />
                </Accordion>
              </div>
            )}

            {/* Settings section */}
            {user!.role === "admin" && (
              <div>
                <Accordion title="Kullanıcılar">
                  <MenuItems
                    title="Kullanıcı Listesi"
                    icon={faUsers}
                    to={url("Admin.Users")}
                  />
                  <MenuItems
                    title="Eğitmen Listesi"
                    icon={faUsersRectangle}
                    to={url("Admin.Users.Teachers")}
                  />
                  <MenuItems
                    title="Kullanıcı Oluştur"
                    icon={faPlus}
                    to={url("Admin.Users.Create")}
                  />
                </Accordion>
                <Accordion title="Siparişler">
                  <MenuItems
                    title="Sipariş Listesi"
                    icon={faImages}
                    to={url("Admin.Orders")}
                  />
                  <MenuItems
                    title="Sipariş Oluştur"
                    icon={faPlus}
                    to={url("Admin.Orders.Create")}
                  />
                </Accordion>
                <Accordion title="Faturalar">
                  <MenuItems
                    title="Fatura Listesi"
                    icon={faImages}
                    to={url("Admin.Invoices")}
                  />
                </Accordion>
                <Accordion title="Kategoriler">
                  <MenuItems
                    title="Kategori Listesi"
                    icon={faList}
                    to={url("Admin.Categories")}
                  />
                  <MenuItems
                    title="Kategori Ekle"
                    icon={faPlus}
                    to={url("Admin.Categories.Create")}
                  />
                </Accordion>
                <Accordion title="Afiş">
                  <MenuItems
                    title="Afiş Listesi"
                    icon={faImages}
                    to={url("Admin.Sliders")}
                  />
                  <MenuItems
                    title="Afiş Oluştur"
                    icon={faPlus}
                    to={url("Admin.Sliders.Create")}
                  />
                </Accordion>
                <MenuItems
                  title="İçerikler"
                  icon={faFolder}
                  to={url("Admin.Contents")}
                />
                <Accordion title="Sözleşmeler">
                  <MenuItems
                    title="Sözleşme Listesi"
                    icon={faFileWord}
                    to={url("Admin.Agreements")}
                  />
                  <MenuItems
                    title="Sözleşme Oluştur"
                    icon={faPlus}
                    to={url("Admin.Agreements.Create")}
                  />
                </Accordion>
                <Accordion title="Ayarlar">
                  <MenuItems
                    title="Genel Ayarlar"
                    icon={faGear}
                    to={url("Admin.Site-Settings.General-Settings")}
                  />
                </Accordion>
              </div>
            )}

            {/* Account section */}
            {user?.role === "teacher" && (
              <div>
                <Accordion title="Hesap">
                  <MenuItems
                    title="Özgeçmişimi Düzenle"
                    icon={faUser}
                    to={url("Admin.Cv", { id: user?._id })}
                  />
                  {/* <MenuItems title="Bildirimler" icon={faBell} to="#" /> */}
                </Accordion>
              </div>
            )}
          </ul>
        </div>
      </div>
      <button
        className="block md:hidden  fixed top-4 left-4 text-gray-400 bg-white"
        onClick={() => setIsOpen(!isOpen)}
      >
        <FontAwesomeIcon icon={faList} />
      </button>
    </div>
  );
}
