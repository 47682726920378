import React, { useEffect, useState } from "react";
import ImagePickerModal from "./ImagePickerModel";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "@/actions/image.actions";
import { ImageDto } from "@/data/dtos/image.dto";

interface ImageUploaderProps {
  onChange: (image: string) => void; // Syncs with CreateSlider
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onChange }) => {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState<ImageDto | null>(null);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { images, status: imageStatus } = useSelector(
    (state: any) => state.images
  );

  useEffect(() => {
    if (imageStatus === "idle") {
      dispatch(getAll() as any);
    }
  }, [imageStatus, dispatch]);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  // When an image is selected, update both `selectedImage` & notify parent
  const selectImage = (image: ImageDto) => {
    setSelectedImage(image);
    onChange(image._id!); // Pass image ID to CreateSlider
    closeModal();
  };

  return (
    <div className="flex flex-col items-center space-y-4 p-4">
      <button
        onClick={openModal}
        className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-700 transition"
      >
        Görsel Ekle
      </button>

      {/* Selected Image Preview */}
      {selectedImage && (
        <div className="border p-2 rounded-lg w-full">
          <p className="text-sm text-gray-600 mb-1">Seçilen Görsel:</p>
          <img
            src={selectedImage.url}
            alt={selectedImage.altText}
            className="w-32 h-32 object-cover rounded-lg shadow-md"
          />
        </div>
      )}

      {/* Image Picker Modal */}
      <ImagePickerModal
        isOpen={isModalOpen}
        images={images}
        onSelect={selectImage}
        onClose={closeModal}
      />
    </div>
  );
};

export default ImageUploader;
