import React from "react";
import CategoryButton from "../core/home/CategoryButton";
import CardSlider from "./CardSlider";
import FictionalApplyForm from "./FictionalApplyForm";

export default function HomeCategorySelection({
  categories,
  selectedCourse,
  setSelectedCourse,
  handleChangeCategory,
}: any) {
  return (
    <section className="container mx-auto py-20">
      {/* Kategori Butonları */}
      <div className="flex justify-center gap-4">
        {categories?.categories?.map((category: any, index) =>
          category.parent === null ? (
            <CategoryButton
              key={category._id}
              category={category}
              isSelected={selectedCourse?._id === category._id}
              onClick={() => handleChangeCategory(index)}
            />
          ) : null
        )}
        <button
          onClick={() => handleChangeCategory(99)}
          className={`border border-gray-600  rounded-full shadow-md p-4 hover:scale-105 cursor-pointer text-black ${
            selectedCourse === undefined ? "bg-orange-500" : "bg-orange-50"
          }`}
        >
          <h2 className="font-semibold">Kurgusal Etkinlik</h2>
        </button>
      </div>

      <hr className="border-gray-600 my-4" />

      {/* Kurs Kartları */}
      {selectedCourse === undefined ? (
        <div className=" flex flex-col gap-4 w-full justify-items-center">
          <h2 className="text-3xl font-bold text-center">
            Kurgusal Etkinlik İle Ne Demek İstiyoruz?
          </h2>
          <div className="text-justify" style={{ textIndent: "2rem" }}>
            <p className="max-w-4xl mx-auto">
              Kurumların ihtiyacı olan etkinlik ve organizasyonlarda ilham veren
              konuşmaları kurumunuzun ihtiyacına yönelik özelleştirerek
              sunmaktan söz ediyoruz. Özetle standart içerikteki eğitim
              paketleri ile seslendiğimiz online eğitim platformumuzdan farklı
              olarak sizleri burada özelleştirilmiş içerik ile buluşturmayı vaat
              ediyoruz ki yeni nesil iş dünyası için bu noktada tercih edilir
              olacağımız kanaatindeyiz.
            </p>
          </div>
          <FictionalApplyForm />
        </div>
      ) : (
        <CardSlider selectedCourse={selectedCourse} />
      )}
    </section>
  );
}
