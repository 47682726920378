import { AddressDto } from "@/data/dtos/address.dto";
import { logger } from "@/lib/default-logger";

interface AddressState {
  addresses: any[];
  currentAddress: any;
  status: string;
}

const initialState: AddressState = {
  addresses: [],
  currentAddress: null,
  status: "idle",
};

export default function addressReducer(state = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case "FETCH_ADDRESSES_SUCCESS":
      return {
        ...state,
        addresses: payload,
        status: "success",
      };
    case "FETCH_ADDRESS_SUCCESS":
      return {
        ...state,
        currentAddress: payload,
        status: "success",
      };
    case "CREATE_ADDRESS_SUCCESS":
      return {
        ...state,
        addresses: [...state.addresses, payload.data],
        currentAddress: payload.data,
        status: "success",
      };

    case "UPDATE_ADDRESS_SUCCESS":
      return {
        ...state,
        addresses: [...state.addresses].map((address: any) =>
          address._id === payload.data._id ? { ...payload.data } : address
        ),
        currentAddress: { ...payload.data }, // Eğer başka bir yerde currentAddress referansı kullanılıyorsa
        status: "success",
      };

    case "DELETE_ADDRESS_SUCCESS":
      return {
        ...state,
        addresses: state.addresses.filter((address: any) => {
          console.log(address._id, payload);
          return address._id !== payload.data._id;
        }),
        currentAddress: null,
        status: "success",
      };

    case "CREATE_ADDRESS_FAIL":
    case "UPDATE_ADDRESS_FAILED":
    case "DELETE_ADDRESS_FAILED":
    case "FETCH_ADDRESSES_FAILED":
    case "FETCH_ADDRESS_FAILED":
      return {
        ...state,
        status: "failed",
      };
    default:
      return state;
  }
}
