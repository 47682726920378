import { getAllAuth } from "@/actions/courses.actions";
import CourseTable from "@/components/auth/admin/courses/CourseTable";
import CreateCourseCard from "@/components/auth/admin/courses/CreateCourseCard";
import { CourseDto } from "@/data/dtos/course.dto";
import { CourseStatus } from "@/data/enums/course-status.enum";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";

export default function PendingCourses() {
  const dispatch = useDispatch();
  const { courses, listStatus } = useSelector((state: any) => state.courses);
  const [pendingCourses, setPendingCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const fetchCourses = async () => {
      if (isMounted) {
        dispatch(getAllAuth() as any);
      }
      setLoading(false);
    };
    fetchCourses();
    return () => {
      isMounted = false;
    };
  }, [dispatch, listStatus]);

  useEffect(() => {
    if (courses?.length > 0) {
      setPendingCourses(
        courses?.filter(
          (course: CourseDto) =>
            course.status === CourseStatus.PENDING && !course.isOnSale
        )
      );
    }
  }, [courses]);
  if (loading) {
    return <Skeleton count={10} height={50} />;
  }
  if (pendingCourses?.length > 0) {
    const rows = pendingCourses?.map((item: CourseDto, index) => ({
      id: item._id,
      no: index + 1, // Her satıra benzersiz bir ID
      title: item.title,
      saleStart: item.startDate,
      level: item.level,
      price: item.price,
      discount: item.price * (item.discountPercentage / 100),
      tax: item.tax,
      status: item.status,
      isOnSale: item.isOnSale,
      thumbnail: item.images?.[0].url,
      categories: item.categories,
    }));

    return <CourseTable tableTitle="Onay Bekleyen Atölyeler" data={rows} />;
  }
  return <CreateCourseCard title="Atölye bulunamadı" cta="Atölye Ekle" />;
}
